import { css, Stack, styled } from "@mui/material";

export const commonScrollbar = css`
	::-webkit-scrollbar {
		width: 8px;
		max-height: 10px;
		border-radius: 8px;
	}

	::-webkit-scrollbar-track {
		background: #e6e6e6;
		border-radius: 8px;
	}

	::-webkit-scrollbar-thumb {
		background: #8fd7ff;
		height: 40px;
		border-radius: 8px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #8fe5f5;
	}
`;

export const ListWrapperScrollbar = styled(Stack)`
	position: relative;
	${commonScrollbar}
`;
