import { api } from "services/axios/api";
import { ApiResponse } from "shared/interfaces/api";

interface GetVersion {
	env: string;
	versao: string;
}

export const getVersion = async () => {
	try {
		const response = await api
			.get<ApiResponse<GetVersion>>("/comum/versao")
			.then((res) => res.data);

		return response.data;
	} catch (error: any) {
		return {
			env: "",
			versao: "",
		};
	}
};
