import { Stack, SxProps, Theme } from "@mui/material";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { HeaderModal } from "./HeaderModal";

const style: SxProps<Theme> = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: 1,
};

interface ModelProps {
	open: boolean;
	handleClose: () => void;
	children: React.ReactNode;
	width?:
		| number
		| string
		| {
				xl?: number | string;
				lg?: number | string;
				md?: number | string;
				sm?: number | string;
				xs?: number | string;
		  };
	height?:
		| number
		| string
		| {
				xl?: number | string;
				lg?: number | string;
				md?: number | string;
				sm?: number | string;
				xs?: number | string;
		  };
	title?: string;
	isAlert?: boolean;
	header?: React.ReactNode;
	sx?: SxProps<Theme>;
	sxModal?: SxProps<Theme>;
	sxStackChildren?: SxProps<Theme>;
}

const ModalComponent: React.FC<ModelProps> = ({
	open,
	handleClose,
	children,
	width = 400,
	height = 690,
	title,
	isAlert = false,
	header,
	sx,
	sxModal,
	sxStackChildren,
}) => {
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			sx={sxModal}
		>
			<Stack width={width} height={height} sx={{ ...style, ...sx }}>
				{title && (
					<HeaderModal
						title={title}
						onClickClose={handleClose}
						hasDivider={!isAlert}
					/>
				)}
				{header}
				<Stack
					flex={1}
					sx={{
						overflow: "hidden",
						...sxStackChildren,
					}}
				>
					{children}
				</Stack>
			</Stack>
		</Modal>
	);
};

export default ModalComponent;
