export function parseParams(params: any) {
	const keys = Object.keys(params);
	let options = "";

	keys.forEach((key) => {
		const isParamTypeObject = typeof params[key] === "object";
		const isParamTypeArray = isParamTypeObject && params[key]?.length >= 0;

		if (!isParamTypeObject) {
			options += `${key}=${params[key]}&`;
		}

		if (isParamTypeObject && isParamTypeArray) {
			params[key].forEach((element: any) => {
				options += `${key}=${element}&`;
			});
		}
	});

	return options ? options.slice(0, -1) : options;
}
