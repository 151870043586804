import styled from "@emotion/styled";
import { Box, Popover, TextField } from "@mui/material";
import { maskfy } from "shared/mask";
import { TextFieldProps } from ".";

export const FloatInput = styled(TextField)({
	"& .MuiFilledInput-root": {
		backgroundColor: "#EBF4F9",
		borderRadius: "4px",
	},
	"& label.Mui-focused": {
		color: "#005C8F",
	},
	"& .MuiFilledInput-root.Mui-disabled": {
		backgroundColor: "#F5F5F5",
	},
});

type InputPopoverProps = {
	anchor: HTMLDivElement | null;
	onClose: () => void;
} & TextFieldProps;

export default function InputPopover({
	anchor,
	onClose,
	value,
	uppercase,
	mask,
	...props
}: InputPopoverProps) {
	const open = Boolean(anchor);
	const id = open ? "input-popover" : undefined;

	return (
		<Popover
			id={id}
			open={open}
			anchorEl={anchor}
			onClose={onClose}
			anchorOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			sx={{
				"& .MuiPopover-paper": {
					borderRadius: 2,
				},
			}}
		>
			<Box p={1} width={500} borderRadius="8px">
				<FloatInput
					{...props}
					autoFocus
					fullWidth
					variant="filled"
					InputProps={{ disableUnderline: true }}
					inputProps={{ maxLength: props.maxLength }}
					value={maskfy(
						uppercase ? String(value).toUpperCase() : String(value),
						mask,
					)}
				/>
			</Box>
		</Popover>
	);
}
