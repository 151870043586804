import axios from "axios";

export const baseURL = process.env.REACT_APP_BASE_URL;

export const api = axios.create({
	baseURL: baseURL,
	headers: { "Content-Type": "application/json" },
});

api.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response?.status === 401) {
			localStorage.clear();
			window.location.replace("/login");
		}
		return Promise.reject(error.response);
	},
);

export const setAuthorization = (token: string | null): void => {
	api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};
