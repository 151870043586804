import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import {
	Collapse,
	Divider,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	SxProps,
	Typography,
	useTheme,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";

export type MenuItemType = "group" | "item";

export const MenuItem: React.FC<{
	icon?: JSX.Element;
	title: string;
	type: MenuItemType;
	children?: JSX.Element;
	link?: string;
	sx?: SxProps<any>;
	hasDivider?: boolean;
	onClick?: (route: string | undefined) => void;
	isOpenSidebar?: boolean;
	sxTitle?: SxProps<any>;
}> = ({
	icon,
	title,
	type,
	children,
	link,
	onClick,
	sx,
	hasDivider,
	isOpenSidebar,
	sxTitle,
}) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const theme = useTheme();

	const handleClick = () => {
		if (type === "group") setMenuOpen(!menuOpen);
		else onClick?.(link);
	};

	React.useEffect(() => {
		if (!isOpenSidebar) setMenuOpen(false);
	}, [isOpenSidebar]);

	return (
		<>
			<ListItemButton
				onClick={handleClick}
				alignItems="center"
				sx={{
					...sx,
					justifyContent: isOpenSidebar ? "flex-start" : "center",
					alignItems: "center",
				}}
			>
				{icon && (
					<ListItemIcon
						sx={{
							width: 24,
							minWidth: 24,
							height: 24,
							minHeight: 24,
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
							color: "#013d5f",
						}}
					>
						{icon}
					</ListItemIcon>
				)}
				<ListItemText
					sx={{
						marginLeft: theme.spacing(1),
						display: isOpenSidebar ? "flex" : "none",
					}}
					primary={
						<Typography
							color="primary.700"
							variant="body2"
							sx={{
								fontWeight: 500,
								whiteSpace: "nowrap",
								...sxTitle,
							}}
							component={"span"}
						>
							{title}
						</Typography>
					}
				/>
				{type === "group" && isOpenSidebar ? (
					menuOpen ? (
						<ArrowDropUp color="primary" />
					) : (
						<ArrowDropDown color="primary" />
					)
				) : null}
			</ListItemButton>

			{type === "group" && (
				<Collapse in={menuOpen} timeout="auto" unmountOnExit>
					<List
						component="nav"
						sx={{
							marginLeft: theme.spacing(2),
						}}
					>
						{children}
					</List>
				</Collapse>
			)}
			{hasDivider && (
				<Divider
					style={{
						marginLeft: theme.spacing(2),
					}}
					sx={{
						color: "#EDEDED",
					}}
				/>
			)}
		</>
	);
};

export default MenuItem;
