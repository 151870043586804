import { JwtPayload } from "jwt-decode";

export type PermissionRules =
	| "Admin"
	| "Financeiro"
	| "Fiscal"
	| "Tecnico"
	| "Vendedor"
	| "Producao"
	| "Servico";

export enum EnumModulePermissions {
	ORCAMENTO = "Orcamento",
	REL_CORTE_MONTAGEM = "Rel. Corte & Montagem",
	FINANCEIRO = "Financeiro",
	FISCAL = "Fiscal",
	PRODUCAO = "Producao",
	SERVICOS = "Servicos",
}

export interface JWTToken extends JwtPayload {
	Inquilino: string;
	LoginUsuario: string;
	nameid: string;
	ModulosInquilino: EnumModulePermissions | EnumModulePermissions[];
	role: PermissionRules;
}
