export const codeMask = (value: string) => {
	const regExMatch = value.match(/^\S*$/);
	return regExMatch ? value : value.substr(0, value.length - 1);
};

export const contabilMask = (amount: string) => {
	const newAmount = amount.replace(/\D/g, "").replace(/([0-9]{2})$/g, ",$1");

	if (newAmount.length > 6) {
		return newAmount.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
	}

	return newAmount;
};
export const noSpaceMask = (value: string) => {
	return value.replace(/\s+/g, "");
};

export const onlyNumbersMask = (value: string | number) => {
	return value.toString().replace(/[^\d]/g, "");
};

export const convertCpfCnpf = (v: string) => {
	if (!v) return "";
	v = v.replace(/\D/g, "");

	if (v.length <= 11) {
		v = v.replace(/(\d{3})(\d)/, "$1.$2");
		v = v.replace(/(\d{3})(\d)/, "$1.$2");
		v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
	} else {
		v = v.replace(/^(\d{2})(\d)/, "$1.$2");
		v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
		v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
		v = v.replace(/(\d{4})(\d)/, "$1-$2");
	}
	return v;
};

export const removeMaskCpfCnpj = (v: string) => {
	return v.replace(/[^0-9]/g, "");
};

export const convertFoneCell = (v: string) => {
	if (!v) return "";
	v = v.replace(/\D/g, "");

	if (v.length <= 11) {
		v = v.replace(/(\d{0})(\d)/, "$1($2");
		v = v.replace(/(\d{2})(\d)/, "$1)$2");
		v = v.replace(/(\d{3})(\d{1,4})$/, "$1-$2");
	} else {
		v = v.replace(/(\d{0})(\d)/, "$1($2");
		v = v.replace(/(\d{2})(\d)/, "$1)$2");
		v = v.replace(/(\d{3})(\d{1,4})$/, "$1-$2");
	}
	return v;
};

export const convertCep = (v: string) => {
	v = v.replace(/\D/g, "");
	v = v.replace(/(\d{2})(\d{1,3})$/, "$1-$2");
	return v;
};

export const digitsOnly = (value: string) => /^\d+$/.test(value);

export function formatMoney(amount: number) {
	const decimal = amount?.toFixed(2).replace(".", ",");

	if (decimal === undefined) return "0,00";

	const regex = "\\d(?=(\\d{3})+\\D)";
	return decimal.replace(new RegExp(regex, "g"), "$&.");
}

export function removeMoneyMask(money: string): number {
	const value = extractNumber(money);
	return Number(value) / 100;
}

export const moneyMask = (amount?: string, prefix = "") => {
	if (!amount) return "";
	const value = Number(extractNumber(amount)) / 100;
	return prefix + formatMoney(value);
};

export const extractNumber = (value: string): string => {
	return value.replace(/[^0-9]/g, "");
};

export const weightMask = (amount?: string) => {
	if (!amount) return "";

	const weight = extractNumber(amount);

	const isCleaning = weight === "000";

	if (!weight || isCleaning) return "";

	const decimalWeight = (Number(weight) / 1000).toFixed(3);

	return decimalWeight.toString();
};

export const convertWeightForNumber = (weight: string) => {
	return Number(weightMask(weight));
};

const mask = (name: string, value: string) => {
	const newName = name.includes("weight") ? "weight" : name;

	/*eslint-disable*/
	switch (newName) {
		case "code":
			return codeMask(value);
		case "specialSizeOne":
		case "specialSizeTwo":
		case "specialSizeThree":
		case "priceByMeter":
		case "priceByBar":
			return contabilMask(value);
		case "weight":
			return weightMask(value);
		default:
			return value;
	}
	/*eslint-enable*/
};

const discountMask = (value: string): string => {
	if (!value) return "";
	const amount = Number(extractNumber(value));
	if (amount >= 100) return String(100);
	return amount.toString();
};

export type MaskType = "money" | "discount";

export const masker = (value: string, type: MaskType) => {
	if (type === "money") {
		return contabilMask(value);
	}
	return value;
};

export const bankAccountMask = (value: string) => {
	value = value.replace(/\D/g, "");

	if (value.length > 5) {
		return value.slice(0, -1) + "-" + value.slice(-1);
	} else {
		return value;
	}
};

const uppercaseNoSpace = (value: string) => noSpaceMask(value.toUpperCase());

export default mask;

export type MaskTypes =
	| "money"
	| "no-space"
	| "only-numbers"
	| "discount"
	| "percentSuffix"
	| "cpfCnpj"
	| "cep"
	| "foneCell"
	| "weight"
	| "decimal"
	| "custom"
	| "date"
	| "numberAccount"
	| "upperCase-no-space";

export function maskfy(value: string, maskType?: MaskTypes) {
	if (!maskType) return value;

	const masks = {
		money: (money: string) => moneyMask(money, "R$ "),
		"no-space": (value: string) => noSpaceMask(value),
		"only-numbers": (value: string) => onlyNumbersMask(value),
		discount: (value: string) => discountMask(value),
		weight: (value: string) => weightMask(value),
		cpfCnpj: (value: string) => convertCpfCnpf(value),
		cep: (value: string) => convertCep(value),
		foneCell: (value: string) => convertFoneCell(value),
		decimal: (value: string) => value,
		percentSuffix: (value: string) => value,
		custom: (value: string) => value,
		date: (value: string) => value,
		numberAccount: (value: string) => bankAccountMask(value),
		"upperCase-no-space": (value: string) => uppercaseNoSpace(value),
	};

	return masks[maskType]?.(value) ?? value;
}
