import { WebccRuntime, useToggleDisplay } from "@alumisoft/package_desenhador";
import { ProcessVariablesDesenhadorType } from "hooks/useDesenhador";
import { MaximarData } from "pages/Orcamento/components/Projeto/OpcaoMontagem";
import {
	useState,
	createContext,
	useEffect,
	MutableRefObject,
	useMemo,
	useRef,
} from "react";
import { useLocation } from "react-router-dom";
import { DataTypeDesenhador } from "shared/interfaces/desenhador";

export type DesenhadorContextProvider = {
	children: JSX.Element;
};

export type RetrievaDrawFuncRefType =
	| MutableRefObject<((data: DataTypeDesenhador) => void) | undefined>
	| undefined;

export type DesenhadorColor = {
	perfil: string;
	vidro: string;
};

export type DesenhadorContextValues = {
	jsonToLoad: string;
	handleJsonToLoad: (jsonString: string) => void;
	isDesenhadorOpen: boolean;
	handleIsDesenhadorOpen: (value: boolean) => void;
	toggleDisplayDesenhador: (shouldShow: boolean) => void;
	processVariables?: ProcessVariablesDesenhadorType;
	handleProcessVariables: (data: ProcessVariablesDesenhadorType) => void;
	maximarData?: MaximarData;
	handleMaximarData: (data: MaximarData) => void;
	colorData?: DesenhadorColor;
	handleColorData: (data: DesenhadorColor) => void;
	handleDrawData?: RetrievaDrawFuncRefType;
	handleHandleDrawData: (ref: RetrievaDrawFuncRefType) => void;
	resetDataDesenhador: () => void;
	ccRef: MutableRefObject<WebccRuntime | undefined>;
	konvaRef: MutableRefObject<any>;
};

export const DesenhadorContext = createContext({} as DesenhadorContextValues);

export const DesenhadorProvider = ({ children }: DesenhadorContextProvider) => {
	const { isDesenhadorOpen, setIsDesenhadorOpen, toggleDisplayDesenhador } =
		useToggleDisplay();

	const ccRef = useRef<WebccRuntime>();
	const konvaRef = useRef<any>();

	const handleIsDesenhadorOpen = (value: boolean) => {
		setIsDesenhadorOpen(value);
	};

	const [jsonToLoad, setJsonToLoad] = useState("");

	const handleJsonToLoad = (jsonString: string) => {
		setJsonToLoad(jsonString);
	};

	const [processVariables, setProcessVariables] =
		useState<ProcessVariablesDesenhadorType>();

	const handleProcessVariables = (
		data: ProcessVariablesDesenhadorType | undefined,
	) => {
		setProcessVariables(data);
	};

	const [maximarData, setMaximarData] = useState<MaximarData>();

	const handleMaximarData = (data: MaximarData | undefined) => {
		setMaximarData(data);
	};

	const [colorData, setColorData] = useState<DesenhadorColor>();

	const handleColorData = (data?: DesenhadorColor) => {
		setColorData(data);
	};

	const [handleDrawData, setHandleDrawData] =
		useState<RetrievaDrawFuncRefType>();

	const handleHandleDrawData = (ref: RetrievaDrawFuncRefType) => {
		setHandleDrawData(ref);
	};

	const myLocation = useLocation();

	useEffect(() => {
		return () => {
			resetDataDesenhador();
		};
	}, [myLocation]);

	const resetDataDesenhador = () => {
		toggleDisplayDesenhador(false);
		handleJsonToLoad("");
		handleProcessVariables(undefined);
		handleMaximarData(undefined);
		handleHandleDrawData(undefined);
		handleColorData(undefined);
	};

	const values = useMemo(() => {
		return {
			jsonToLoad,
			handleJsonToLoad,
			isDesenhadorOpen,
			handleIsDesenhadorOpen,
			toggleDisplayDesenhador,
			processVariables,
			handleProcessVariables,
			maximarData,
			handleMaximarData,
			colorData,
			handleColorData,
			handleDrawData,
			handleHandleDrawData,
			resetDataDesenhador,
			ccRef,
			konvaRef,
		};
	}, [
		jsonToLoad,
		isDesenhadorOpen,
		processVariables,
		maximarData,
		colorData,
		handleDrawData,
		ccRef,
		konvaRef,
	]);

	return (
		<DesenhadorContext.Provider value={values}>
			{children}
		</DesenhadorContext.Provider>
	);
};
