import { css, Stack, styled, Typography } from "@mui/material";
import { CustomButton } from "components/CustomButtom";
import { useNavigate } from "react-router-dom";

const backgroundAnimate = css`
	background-image: linear-gradient(245deg, #42bbff 0%, #005c8f 100%);
	background-size: 300% 300%;

	animation: animated_bg 5s infinite alternate;

	@keyframes animated_bg {
		0% {
			background-position: 0% 0%;
		}

		50% {
			background-position: 50% 75%;
		}

		100% {
			background-position: 25% 100%;
		}
	}
`;

const CustomBox = styled(Stack)`
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	${backgroundAnimate}
`;

export function NotFound() {
	const navigate = useNavigate();

	return (
		<>
			<CustomBox>
				<Typography
					variant="h1"
					sx={{
						textAlign: "center",
						fontSize: "10em",
						fontWeight: 100,
						color: "#fff",
					}}
				>
					404
				</Typography>
				<Typography
					sx={{
						fontSize: "1.5em",
						textAlign: "center",
						fontWeight: 100,
						mb: 8,
						color: "#fff",
					}}
				>
					Página não encontrada!
				</Typography>
				<CustomButton
					variant="outlined"
					onClick={() => {
						navigate(-1);
					}}
					color="inherit"
					sx={{
						borderColor: "#fff",
						color: "#fff",
					}}
				>
					Voltar
				</CustomButton>
			</CustomBox>
		</>
	);
}
