import { api } from "../../axios/api";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";

export const postSendEmail = async (email: string) => {
	try {
		await api.post("/autenticacao/solicitar-alteracao-senha", {
			email: email,
		});
	} catch (error: any) {
		getErrorsByApi(error, "Tivemos problemas ao enviar o e-mail");
	}
};
