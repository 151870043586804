import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
	interface PaletteColor {
		"50"?: string;
		"100"?: string;
		"200"?: string;
		"300"?: string;
		"400"?: string;
		"500"?: string;
		"600"?: string;
		"700"?: string;
		"800"?: string;
		"900"?: string;
	}
}

export const fontsSizes = {
	xsmall: 10,
	small: 12,
	medium: 14,
	large: 16,
	mlarge: 18,
	xlarge: 22,
	xxlarge: 26,
	xxxlarge: 30,
};

const theme = createTheme({
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					overflowX: "hidden",
				},
			},
		},
	},
	palette: {
		mode: "light",
		text: {
			primary: "#282828",
			secondary: "#00000085",
		},
		primary: {
			main: "#005C8F",
			dark: "#005c8f",
			light: "#005C8F",
			"50": "#4E9DCA",
			"100": "#8FD7FF",
			"200": "#42BBFF",
			"300": "#11577d",
			"400": "#007CC2",
			"500": "#005C8F",
			"600": "#004B75",
			"700": "#003B5C",
			"800": "#002A42",
			"900": "#5388D899",
		},
		secondary: {
			main: "#EBF4F9",
			"100": "#F9F9F9",
		},
		action: {
			hover: "#EBF4F9",
		},
		error: {
			main: "#D63657",
			dark: "#D63657",
			light: "#D63657",
			"100": "#FBEAED",
			"300": "#E58095",
		},
		success: {
			main: "#4EAA79",
			dark: "#4EAA79",
			light: "#4EAA79",
			"600": "#399238",
		},
		background: {
			default: "#ECEDED",
		},
	},
	typography: {
		fontFamily: ["Inter", "Roboto"].join(","),
		fontSize: fontsSizes.medium,
		fontWeightRegular: 400,
		caption: {
			fontFamily: "Inter",
			fontWeight: 400,
			fontSize: fontsSizes.medium,
			color: "#000",
		},
		subtitle1: {
			fontWeight: 400,
			color: "#000",
		},
		button: {
			fontSize: fontsSizes.small,
		},
		h3: {
			fontSize: 28,
			"@media (max-width:900px)": {
				fontSize: 20,
			},
			color: "#00000099",
		},
	},
});

export default theme;
