import * as React from "react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useNavigation } from "contexts/notificationsContext";
import { Button } from "@mui/material";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
	props,
	ref,
) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertComponent: React.FC = () => {
	const { message, type, isOpen, closeNotification } = useNavigation();

	const handleClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string,
	) => {
		closeNotification();
	};

	return (
		<Snackbar
			open={isOpen}
			autoHideDuration={6000}
			onClose={handleClose}
			sx={{ maxWidth: "400px" }}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
		>
			<Alert
				onClose={handleClose}
				severity={type}
				elevation={3}
				icon={false}
				variant="standard"
				action={
					<Button onClick={handleClose} color="inherit" size="medium">
						OK
					</Button>
				}
			>
				{message}
			</Alert>
		</Snackbar>
	);
};

export default AlertComponent;
