import { useCallback } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { postSendEmail } from "../services/modules/auth/sendEmail";
import { IUser } from "../shared/interfaces/user";

import { jwtDecode } from "jwt-decode";
import { setAuthorization } from "services/axios/api";
import { decrypt, encrypt } from "shared/helpers/crypto";
import {
	EnumModulePermissions,
	JWTToken,
	PermissionRules,
} from "shared/interfaces/authPermission";
import { IAuth } from "shared/interfaces/signIn";
import { usePersistState } from "../hooks/usePersistState";
import {
	postCheckSession,
	postSignIn,
	validSession,
} from "../services/modules/auth/signIn";
import { usePostHog } from "posthog-js/react";

export type AuthContextProvider = {
	children: JSX.Element;
};

export type AuthContextValues = {
	user: IUser | null;
	token: string | null;
	password: string | null;
	email: string | null;
	tipoUsuario: PermissionRules;
	modulosInquilino: EnumModulePermissions | EnumModulePermissions[];
	onSignIn: (username: string, password: string) => Promise<IAuth | null>;
	onSignOut: () => void;
	sendEmail: (email: string) => Promise<void>;
	onCheckSession: (
		username: string,
		password: string,
	) => Promise<{ success: boolean; messages: string[] }>;
	validateSession: () => Promise<boolean>;
};

export const AuthContext = createContext({} as AuthContextValues);

export const AuthProvider = ({ children }: AuthContextProvider) => {
	const posthog = usePostHog();
	const [tipoUsuario, setTipoUsuario] = usePersistState<string>(
		"@painel-cemweb:typeuser",
		"",
	);

	const [modulosInquilino, setModulosInquilino] = usePersistState<string>(
		"@painel-cemweb:modules",
		"[]",
	);
	const [user, setUser] = usePersistState<IUser | null>(
		"@painel-cemweb:user",
		null,
	);

	const [token, setAuth] = usePersistState<string | null>(
		"@painel-cemweb:auth",
		null,
	);

	const setPermissionUser = (tokenUser: string) => {
		if (tokenUser) {
			const decoded = jwtDecode(tokenUser ?? "") as JWTToken;

			if (decoded) {
				const roles = decoded?.role ?? [];
				const modulos = decoded?.ModulosInquilino ?? [];

				setTipoUsuario(encrypt(JSON.stringify(roles)));
				setModulosInquilino(encrypt(JSON.stringify(modulos)));
			}
		}
	};

	const onSignIn = useCallback(async (username: string, password: string) => {
		const data = await postSignIn(username, password);

		setUser(data?.user ?? null);
		setAuth(data?.token ?? "");
		setAuthorization(data?.token ?? "");

		setPermissionUser(data?.token ?? "");

		if (data?.user) {
			posthog?.identify(data?.user.id, {
				email: data?.user.email,
				name: data?.user.name,
			});
			posthog?.group("company", data?.user.dadoInquilino);
		}

		return data;
	}, []);

	const onCheckSession = useCallback(
		async (username: string, password: string) => {
			const { success, messages } = await postCheckSession(username, password);
			return { success, messages };
		},
		[],
	);

	const onSignOut = useCallback(() => {
		sessionStorage.removeItem("@painel-cemweb:typeuser");
		sessionStorage.removeItem("@painel-cemweb:user");
		sessionStorage.removeItem("@painel-cemweb:auth");
		sessionStorage.removeItem("@painel-cemweb:modules");
		posthog?.reset();
	}, []);

	const sendEmail = useCallback(async (email: string) => {
		await postSendEmail(email);
	}, []);

	const validateSession = useCallback(async () => {
		return await validSession();
	}, []);

	return (
		<AuthContext.Provider
			value={{
				user,
				token,
				password: null,
				email: null,
				tipoUsuario: decrypt(tipoUsuario) as PermissionRules,
				modulosInquilino: decrypt(modulosInquilino) as EnumModulePermissions[],
				onSignIn,
				onSignOut,
				sendEmail,
				onCheckSession,
				validateSession,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = (): AuthContextValues => {
	const user = useContextSelector(AuthContext, (user) => user.user);
	const tipoUsuario = useContextSelector(
		AuthContext,
		(user) => user.tipoUsuario,
	);
	const modulosInquilino = useContextSelector(
		AuthContext,
		(user) => user.modulosInquilino,
	);
	const token = useContextSelector(AuthContext, (user) => user.token);
	const password = useContextSelector(AuthContext, (user) => user.password);
	const email = useContextSelector(AuthContext, (user) => user.email);
	const onSignIn = useContextSelector(AuthContext, (user) => user.onSignIn);
	const onSignOut = useContextSelector(AuthContext, (user) => user.onSignOut);
	const sendEmail = useContextSelector(AuthContext, (user) => user.sendEmail);
	const onCheckSession = useContextSelector(
		AuthContext,
		(user) => user.onCheckSession,
	);
	const validateSession = useContextSelector(
		AuthContext,
		(user) => user.validateSession,
	);
	return {
		user,
		token,
		password,
		email,
		tipoUsuario,
		modulosInquilino,
		onSignIn,
		onSignOut,
		sendEmail,
		onCheckSession,
		validateSession,
	};
};
