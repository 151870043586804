import { Close } from "@mui/icons-material";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

interface HeaderModalProps {
	title: string;
	onClickClose: () => void;
	hasDivider?: boolean;
	children?: ReactNode;
}

export function HeaderModal({
	title,
	onClickClose,
	hasDivider,
	children,
}: HeaderModalProps) {
	return (
		<>
			<Stack direction="row" alignItems="center" px={3} py={{ xl: 2, xs: 1 }}>
				<Typography variant="body2" align="left" mr="auto">
					{title}
				</Typography>
				{children}
				<IconButton aria-label="delete" onClick={onClickClose}>
					<Close />
				</IconButton>
			</Stack>
			{hasDivider && <Divider />}
		</>
	);
}
