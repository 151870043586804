import { api } from "services/axios/api";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import { ApiResponse } from "shared/interfaces/api";
import {
	IPostValidarToken,
	IPutCadastroUsuarioForm,
	IPutToken,
} from "shared/interfaces/pessoas";

export const putAtualizarUsuario = async (params: IPutCadastroUsuarioForm) => {
	try {
		const response = await api.put("/autenticacao/usuario/atualizar", params);
		return response.data;
	} catch (error: any) {
		getErrorsByApi(error, "Tivemos problemas internos ao ativar o usuário");
	}
	return null;
};

export const postToken = async (params: IPutToken) => {
	try {
		const response = await api.post("/autenticacao/reenviar-token", params);
		return response.data;
	} catch (error: any) {
		getErrorsByApi(error, "Tivemos problemas internos ao ativar o usuário");
	}

	return null;
};

export const postValidarToken = async (params: IPostValidarToken) => {
	try {
		const response: ApiResponse<boolean> = await api
			.post("/autenticacao/validar-token", params)
			.then((res) => res.data);

		return response.data;
	} catch (error: any) {
		getErrorsByApi(error, "Tivemos problemas internos ao validar o token");
	}
};
