import { Stack, SxProps, Theme } from "@mui/material";
import { SearchInput, SearchInputProps } from "components/SearchInput";
import * as React from "react";

type SearchBarOfTableProps = {
	children?: React.ReactNode;
	maxWidth?: string | number;
	mrChildren?: number;
	endAdornment?: React.ReactNode;
	startAdornment?: React.ReactNode;
	sxStack?: SxProps<Theme>;
} & SearchInputProps;

export const SearchBarOfTable: React.FC<SearchBarOfTableProps> = ({
	children,
	placeholder = "Faça uma busca",
	maxWidth = { md: 250, xs: "100%" },
	mrChildren = 2,
	endAdornment,
	startAdornment,
	sxStack,
	...props
}) => {
	return (
		<Stack direction="row" alignItems="center" sx={sxStack}>
			<Stack flex={1} mr={mrChildren}>
				{children}
			</Stack>

			{startAdornment}

			<SearchInput
				{...props}
				placeholder={placeholder}
				sx={{ ...props.sx, maxWidth }}
			/>
			{endAdornment}
		</Stack>
	);
};
