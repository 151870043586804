import { Check, ChevronRight } from "@mui/icons-material";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { CustomButton } from "components/CustomButtom";
import styled from "styled-components";

type ClienteProps<T = any> = {
	values: T;
	fields: Partial<{ [key in keyof T]: string }>;
	showImage?: boolean;
	buttonText?: string;
	keyExtractor: (values: T) => string | number;
	onPressButton?: () => void;
	isSelected?: boolean;
};

export function PessoasCard<T = any>({
	fields,
	values,
	keyExtractor,
	onPressButton,
	buttonText,
	isSelected,
}: ClienteProps<T>) {
	type FieldKey = keyof T;

	return (
		<Container>
			<Stack spacing={2} p={2} flex={1}>
				{Object.keys(fields).map((item) => (
					<Stack key={item + keyExtractor(values)}>
						<Typography
							fontFamily="Roboto"
							fontWeight={400}
							color="#666666"
							fontSize={12}
						>
							{fields[item as FieldKey]}
						</Typography>
						<Typography
							fontFamily="Roboto"
							fontWeight={700}
							color="#212121"
							fontSize={16}
						>
							{values[item as FieldKey] as unknown as string}
						</Typography>
					</Stack>
				))}
				{Boolean(buttonText) && (
					<Box display="flex" flex={1}>
						<CustomButton
							variant={isSelected ? "contained" : "outlined"}
							endIcon={isSelected ? <Check /> : <ChevronRight />}
							onClick={onPressButton}
							sx={{ width: "100%", marginTop: "auto" }}
						>
							{buttonText}
						</CustomButton>
					</Box>
				)}
			</Stack>
		</Container>
	);
}

export const Container = styled(Paper)`
	overflow: hidden;
	height: 100%;
	display: flex;
	flex-direction: column;
`;
