import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import { IAuth, IAuthError, IGetAuth } from "../../../shared/interfaces/signIn";
import { api } from "../../axios/api";
import { ApiResponse } from "shared/interfaces/api";

export const postSignIn = async (
	usuario: string,
	senha: string,
): Promise<IAuth | null> => {
	try {
		const { data }: ApiResponse<IGetAuth> = await api
			.post("/autenticacao/autenticar", {
				nomeUsuario: usuario,
				senha: senha,
				inativarSessaoAnterior: true,
			})
			.then((res) => res.data);

		const auth: IAuth = {
			token: data.token,
			user: {
				id: data.idUsuario.toString(),
				email: data.email,
				name: data.nome,
				username: usuario,
				urlLogo: data.urlLogo,
				dadoInquilino: data.dadoInquilino,
				idPessoa: data.idPessoa.toString(),
			},
		};

		return auth;
	} catch (error: any) {
		getErrorsByApi(error, "Tivemos problemas internos ao realizar login");
	}
	return null;
};

export const postCheckSession = async (
	usuario: string,
	senha: string,
): Promise<IAuthError> => {
	return await api.post("/autenticacao/autenticar", {
		nomeUsuario: usuario,
		senha: senha,
		inativarSessaoAnterior: false,
	});
};

export const validSession = async () => {
	try {
		await api.get("/autenticacao/validar-sessao");
		return true;
	} catch {
		return false;
	}
};

export const postSignOut = async () => {
	try {
		await api.post("/autenticacao/finalizar-sessao");
	} catch (error: any) {
		getErrorsByApi(error, "Tivemos problemas internos ao sair");
	}
};
