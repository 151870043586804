import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import { CustomButton } from "components/CustomButtom";
import { ReactNode } from "react";

interface ContainerYesNoButtonsProps {
	onNo: React.MouseEventHandler<HTMLButtonElement>;
	onYes: React.MouseEventHandler<HTMLButtonElement>;
	isLoading?: boolean;
	children?: ReactNode;
	mx?: number;
	my?: number;
}

export function ContainerYesNoButtons({
	onNo,
	onYes,
	isLoading,
	children,
	mx = 3,
	my = 2,
}: ContainerYesNoButtonsProps) {
	return (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="end"
			mx={mx}
			my={my}
		>
			{children}
			<CustomButton
				variant="contained"
				onClick={onNo}
				sx={{ mr: 2 }}
				color="error"
				disabled={isLoading}
			>
				Não
			</CustomButton>
			<LoadingButton
				onClick={onYes}
				loading={isLoading}
				variant="contained"
				sx={{
					px: 4,
					py: 1,
				}}
			>
				Sim
			</LoadingButton>
		</Stack>
	);
}
