import { SidebarContext } from "contexts/sidebarContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export interface IUsePreventExit {
	isOpenModalUnsaved: boolean;
	handleOnCloseModalUnsavedEdit: () => void;
	handleIsOpenModalUnsaved: (isOpen: boolean) => void;
	handleLinkNavigateBlocked: (linkBlocked: string) => void;
	goBack: (isBlock: boolean, route?: string) => void;
	linkNavigateBlocked: string;
	isGoBack: boolean;
}

export function usePreventExit(blockNavigation: boolean): IUsePreventExit {
	const navigate = useNavigate();
	const {
		handleIsNavigateBlocked,
		linkNavigateBlocked,
		handleLinkNavigateBlocked,
	} = useContext(SidebarContext);
	const [isOpenModalUnsaved, setIsOpenModalUnsaved] = useState(false);
	const [isGoBack, setIsGoBack] = useState(false);

	const handleOnCloseModalUnsavedEdit = () => {
		handleIsNavigateBlocked(false);
		setIsOpenModalUnsaved(false);
		handleLinkNavigateBlocked("");
	};

	const handleIsOpenModalUnsaved = (isOpen: boolean) => {
		setIsOpenModalUnsaved(isOpen);
	};

	function goBack(isBlock: boolean, route?: string) {
		if (!isBlock) {
			if (route) {
				navigate(route);
				return;
			}
			navigate(-1);
		} else {
			handleIsOpenModalUnsaved(true);

			if (route) {
				handleLinkNavigateBlocked(route);
			}
			setIsGoBack(!route);
		}
	}

	useEffect(() => {
		handleIsNavigateBlocked(blockNavigation);

		return () => {
			handleIsNavigateBlocked(false);
			handleLinkNavigateBlocked("");
		};
	}, [blockNavigation]);

	useEffect(() => {
		!!linkNavigateBlocked && setIsOpenModalUnsaved(true);
	}, [linkNavigateBlocked]);

	const values = useMemo(() => {
		return {
			isOpenModalUnsaved,
			handleOnCloseModalUnsavedEdit,
			handleIsOpenModalUnsaved,
			handleLinkNavigateBlocked,
			goBack,
			linkNavigateBlocked,
			isGoBack,
		};
	}, [isOpenModalUnsaved, linkNavigateBlocked, isGoBack]);

	return values;
}
