import { styled, TablePagination } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

export const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
	[`& .${gridClasses.row}.odd`]: {
		backgroundColor: "#EBF4F9",
		"&:hover": {
			backgroundColor: "#EBF4F9",
		},
	},
	border: "none",
	"& .MuiDataGrid-row:hover": {
		boxShadow: `inset 0 0 0 2px ${theme.palette.primary[200]}`,
		backgroundColor: "inherit",
	},
	"& .MuiDataGrid-cell": {
		borderBottom: "none",
		color: "#1F2020",
		fontFamily: "Roboto",
		fontWeight: theme.typography.caption.fontWeight,
		fontSize: theme.typography.caption.fontSize,
	},
	"& .MuiDataGrid-columnHeader": {
		color: "#001A29",
		fontFamily: "Roboto",
		fontWeight: theme.typography.caption.fontWeight,
		fontSize: theme.typography.caption.fontSize,
	},
	"& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
		display: "none",
	},
	"& .MuiDataGrid-columnHeaderTitle": {
		textOverflow: "clip",
		whiteSpace: "break-spaces",
		lineHeight: 1,
	},
	"& .MuiDataGrid-virtualScrollerContent": {
		minHeight: "60vh !important",
	},
}));

export const CustomTablePagination = styled(TablePagination)(({ theme }) => ({
	border: "none",
	"& .MuiTablePagination-selectLabel": {
		fontWeight: theme.typography.caption.fontWeight,
		fontSize: theme.typography.caption.fontSize,
	},
	"& .MuiTablePagination-displayedRows": {
		fontWeight: theme.typography.caption.fontWeight,
		fontSize: theme.typography.caption.fontSize,
	},
	"& .MuiTablePagination-select": {
		backgroundColor: "transparent",
	},
	"& .MuiTablePagination-toolbar": {
		fontFamily: "Roboto",
		fontWeight: theme.typography.caption.fontWeight,
		fontSize: theme.typography.caption.fontSize,
		color: "#757575",
		paddingLeft: 0,
	},
}));
