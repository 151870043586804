import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";

export interface PaginationType {
	search: string;
	page: number;
	pageSize: number;
	orderBy: string;
	direction: "asc" | "desc" | string;
}

export interface UsePaginationProps extends PaginationType {
	totalItemsPage?: number;
}

export const TIME_DEBOUNCE = 600;

export function usePagination({
	search,
	page,
	pageSize,
	orderBy,
	direction,
	totalItemsPage,
}: UsePaginationProps) {
	const [totalItems, setTotalItems] = useState(totalItemsPage ?? 0);
	const [pagination, setPagination] = useState<PaginationType>({
		search: search ?? "",
		page: page ?? 1,
		pageSize: pageSize ?? 10,
		direction: direction ?? "asc",
		orderBy: orderBy ?? "",
	});

	const debouncedSearch = useCallback(
		debounce((value: string) => {
			setPagination((prev) => ({ ...prev, search: value, page: 1 }));
		}, TIME_DEBOUNCE),
		[],
	);

	function handlePagination(currentPagination: Partial<PaginationType>) {
		setPagination((prev) => ({ ...prev, ...currentPagination }));
	}

	function handleTotalItems(total: number) {
		setTotalItems(total);
	}

	const values = useMemo(() => {
		return {
			pagination,
			handlePagination,
			debouncedSearch,
			handleTotalItems,
			totalItems,
		};
	}, [pagination, totalItems]);

	return values;
}
