import { Desenhador } from "@alumisoft/package_desenhador";
import BackFromDesenhador from "components/BackFromDesenhador";
import { DesenhadorContext } from "contexts/desenhadorContext";
import { useContext } from "react";
import { createPortal } from "react-dom";

export default function DesenhadorController() {
	const {
		jsonToLoad,
		isDesenhadorOpen,
		processVariables,
		maximarData,
		handleDrawData,
		colorData,
		toggleDisplayDesenhador,
		ccRef,
		konvaRef,
	} = useContext(DesenhadorContext);

	const divPortal = document.getElementById("desenhador");
	return (
		<>
			{isDesenhadorOpen && (
				<>
					{divPortal &&
						createPortal(
							<BackFromDesenhador
								toggleDisplayDesenhador={toggleDisplayDesenhador}
							/>,
							divPortal,
						)}
					<Desenhador
						loadJSON={jsonToLoad}
						variables={processVariables}
						maximarData={maximarData}
						colors={colorData}
						retrieveDrawData={handleDrawData?.current}
						ccRef={ccRef}
						konvaRef={konvaRef}
					/>
				</>
			)}
		</>
	);
}
