import { Typography, useTheme } from "@mui/material";
import { fontsSizes } from "styles/theme";

interface TitleTableProps {
	title: string;
}

export function TitleTable({ title }: TitleTableProps) {
	const theme = useTheme();

	return (
		<Typography
			fontSize={fontsSizes.xlarge}
			fontWeight={400}
			color="text.secondary"
			sx={{
				[theme.breakpoints.down("md")]: {
					display: "none",
				},
			}}
		>
			{title}
		</Typography>
	);
}
