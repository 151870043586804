import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import theme from "./styles/theme";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/devexpress.css";
import "./styles/reactGridLayout.css";
import "./styles/slickSlider.css";
import "./styles/dropzone.css";
import { CssBaseline } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const rootNode = document.getElementById("root")!;
const root = ReactDOM.createRoot(rootNode);

root.render(
	<ThemeProvider theme={theme}>
		<BrowserRouter>
			<CssBaseline />
			<App />
		</BrowserRouter>
	</ThemeProvider>,
);
