import { Button, Stack, SvgIcon, Typography } from "@mui/material";

import { Logout } from "@mui/icons-material";
import { ReactComponent as iconCem } from "assets/icons/menu/menu_icon_cem_web.svg";
import { useAuth } from "contexts/authContext";
import { GlobalContext } from "contexts/globalContext";
import { useNavigation } from "contexts/notificationsContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { postSignOut } from "services/modules/auth/signIn";
import { fontsSizes } from "styles/theme";
import LogoCem from "../../assets/icons/logo_cem.svg";

interface FooterAppVersionProps {
	isOpenSidebar?: boolean;
}

export default function FooterAppVersion({
	isOpenSidebar,
}: FooterAppVersionProps) {
	const { openNotification } = useNavigation();
	const { onSignOut } = useAuth();
	const { version } = useContext(GlobalContext);
	const navigate = useNavigate();

	const handleLogout = async () => {
		try {
			await postSignOut();
			onSignOut();
			navigate("/");
		} catch (error: any) {
			openNotification({
				isOpen: true,
				message: error?.message,
				type: "error",
			});
		}
	};

	return (
		<Stack direction="row" alignItems="center" justifyContent={"center"}>
			{isOpenSidebar ? (
				<>
					<Stack>
						<img
							src={LogoCem}
							alt="CEM Mobi"
							style={{
								width: 64,
								height: 24,
							}}
						/>
						<Typography fontSize={fontsSizes.small} mt={1}>
							versão {version}
						</Typography>
					</Stack>
					<Button
						variant="text"
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							marginLeft: "auto",
							padding: 0,
							"&.MuiButtonBase-root.MuiButton-root:hover": {
								backgroundColor: "transparent",
							},
						}}
						onClick={handleLogout}
					>
						<Typography
							mr={2}
							fontSize={fontsSizes.medium}
							color="error"
							textTransform={"capitalize"}
						>
							Sair
						</Typography>
						<Logout fontSize="small" color="error" />
					</Button>
				</>
			) : (
				<Stack display={"flex"} justifyContent={"center"} alignItems={"center"}>
					<SvgIcon
						color="primary"
						fontSize="small"
						component={iconCem}
						inheritViewBox
					/>
				</Stack>
			)}
		</Stack>
	);
}
