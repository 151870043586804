import {
	AppBar,
	Box,
	Button,
	Container,
	CssBaseline,
	Drawer,
	Icon,
	IconButton,
	Stack,
	SvgIcon,
	SxProps,
	Toolbar,
	Typography,
	useTheme,
} from "@mui/material";
import { ReactComponent as iconCatalagos } from "assets/icons/menu/icon_catalogos.svg";
import { ReactComponent as iconCadastro } from "assets/icons/menu/icon_menu_cadastros_tecnicos.svg";
import { ReactComponent as iconCliente } from "assets/icons/menu/icon_menu_clientes.svg";
import { ReactComponent as iconConfiguracao } from "assets/icons/menu/icon_menu_configuracao.svg";
import { ReactComponent as iconFinanceiro } from "assets/icons/menu/icon_menu_financeiro.svg";
import { ReactComponent as IconFornecedor } from "assets/icons/menu/icon_menu_fornecedor.svg";
import { ReactComponent as iconFuncionario } from "assets/icons/menu/icon_menu_funcionarios.svg";
import { ReactComponent as iconHome } from "assets/icons/menu/icon_menu_home.svg";
import { ReactComponent as iconObrasProducao } from "assets/icons/menu/icon_menu_obras_producao.svg";
import { ReactComponent as iconOrcamento } from "assets/icons/menu/icon_menu_orcamento.svg";
import { ReactComponent as iconOrdemServico } from "assets/icons/menu/icon_menu_ordem_servico.svg";
import { ReactComponent as iconProjeto } from "assets/icons/menu/icon_menu_projeto.svg";
import { ReactComponent as iconRomaneio } from "assets/icons/menu/icon_menu_romaneio.svg";
import { ReactComponent as iconTarefas } from "assets/icons/menu/icon_menu_tarefas.svg";
import * as React from "react";

import { AuthPermission } from "components/AuthPermission";
import FooterAppVersion from "components/FooterAppVersion";
import { useAuth } from "contexts/authContext";
import { SidebarContext } from "contexts/sidebarContext";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "shared/hooks/useResponsive";
import {
	EnumModulePermissions,
	PermissionRules,
} from "shared/interfaces/authPermission";
import MenuItem, { MenuItemType } from "./MenuItem";
import { AvatarDrawer, DesktopMenuDrawer, StackScroll } from "./styles";

type SubmenuItemType = {
	key: string;
	subtitle: string;
	subtype: MenuItemType;
	sublink?: string;
	subicon?: JSX.Element;
	submenus?: SubmenuItemType[];
	subrule?: PermissionRules[];
	submodule?: EnumModulePermissions[];
	sxsubtitle?: SxProps<any>;
};

type SidebarItemsType = {
	key: string;
	title: string;
	icon?: JSX.Element;
	type: MenuItemType;
	link?: string;
	submenus?: SubmenuItemType[];
	hasDivider?: boolean;
	rule?: PermissionRules[];
	module?: EnumModulePermissions[];
};

interface Props {
	window?: () => Window;
	children: React.ReactNode;
}

export const drawer_width_desktop = 35;
export const THEME_SPACING_WIDTH_SIDEBAR = 10.5;

const financeiroItems: SubmenuItemType[] =
	process.env.REACT_APP_AMBIENT === "qa"
		? [
				{
					key: "dashboard-financeiro",
					subtitle: "Dashboard",
					subtype: "item",
					sublink: "/financeiro/dashboard",
					subrule: ["Admin", "Financeiro"],
					submodule: [EnumModulePermissions.FINANCEIRO],
				},
				{
					key: "contas-a-pagar",
					subtitle: "Contas a pagar",
					subtype: "item",
					sublink: "/financeiro/contas-a-pagar",
					subrule: ["Admin", "Financeiro"],
					submodule: [EnumModulePermissions.FINANCEIRO],
				},
				{
					key: "contas-a-receber",
					subtitle: "Contas a receber",
					subtype: "item",
					sublink: "/financeiro/contas-a-receber",
					subrule: ["Admin", "Financeiro"],
					submodule: [EnumModulePermissions.FINANCEIRO],
				},
				{
					key: "dre",
					subtitle: "Fluxo de Caixa",
					subtype: "item",
					sublink: "/financeiro/dre",
					subrule: ["Admin", "Financeiro"],
					submodule: [EnumModulePermissions.FINANCEIRO],
				},
				{
					key: "nota-fiscal",
					subtitle: "Notas fiscais",
					subtype: "item",
					sublink: "/financeiro/listagem-nota-fiscal",
					subrule: ["Admin", "Fiscal"],
					submodule: [EnumModulePermissions.FISCAL],
				},
				{
					key: "fiscal",
					subtitle: "Cadastro fiscal da empresa",
					subtype: "item",
					sublink: "/financeiro/fiscal",
					subrule: ["Admin", "Fiscal"],
					submodule: [EnumModulePermissions.FISCAL],
				},
				{
					key: "cedentes",
					subtitle: "Cedentes",
					subtype: "group",
					subrule: ["Admin", "Financeiro"],
					submodule: [EnumModulePermissions.FINANCEIRO],
					submenus: [
						{
							key: "cedentes-listagem",
							subtitle: "Cadastro de cedentes",
							subtype: "item",
							sublink: "/financeiro/cedentes",
						},
						{
							key: "contas-bancarias",
							subtitle: "Contas bancárias",
							subtype: "item",
							sublink: "/financeiro/contas-bancarias",
						},
					],
				},
		  ]
		: [
				{
					key: "contas-a-pagar",
					subtitle: "Contas a pagar",
					subtype: "item",
					sublink: "/financeiro/contas-a-pagar",
					subrule: ["Admin", "Financeiro"],
					submodule: [EnumModulePermissions.FINANCEIRO],
				},
				{
					key: "contas-a-receber",
					subtitle: "Contas a receber",
					subtype: "item",
					sublink: "/financeiro/contas-a-receber",
					subrule: ["Admin", "Financeiro"],
					submodule: [EnumModulePermissions.FINANCEIRO],
				},
				{
					key: "dre",
					subtitle: "Fluxo de Caixa",
					subtype: "item",
					sublink: "/financeiro/dre",
					subrule: ["Admin", "Financeiro"],
					submodule: [EnumModulePermissions.FINANCEIRO],
				},
				{
					key: "nota-fiscal",
					subtitle: "Notas fiscais",
					subtype: "item",
					sublink: "/financeiro/listagem-nota-fiscal",
					subrule: ["Admin", "Fiscal"],
					submodule: [EnumModulePermissions.FISCAL],
				},
				{
					key: "fiscal",
					subtitle: "Cadastro fiscal da empresa",
					subtype: "item",
					sublink: "/financeiro/fiscal",
					subrule: ["Admin", "Fiscal"],
					submodule: [EnumModulePermissions.FISCAL],
				},
				{
					key: "contas-bancarias",
					subtitle: "Contas bancárias",
					subtype: "item",
					sublink: "/financeiro/contas-bancarias",
					subrule: ["Admin", "Financeiro"],
					submodule: [EnumModulePermissions.FINANCEIRO],
				},
		  ];

const sidebarItems: SidebarItemsType[] = [
	{
		key: "home",
		title: "Início",
		icon: (
			<SvgIcon
				color="primary"
				component={iconHome}
				inheritViewBox
				width={24}
				height={24}
				fontSize="small"
			/>
		),
		type: "item",
		link: "/dashboard",
		hasDivider: true,
		rule: ["Admin", "Financeiro", "Fiscal"],
	},
	{
		key: "cadastros",
		title: "Cadastros",
		icon: (
			<SvgIcon
				color="primary"
				fontSize="small"
				component={iconCadastro}
				inheritViewBox
				width={24}
				height={24}
			/>
		),
		type: "group",
		rule: ["Admin", "Tecnico"],
		submenus: [
			{
				key: "clientes",
				subtitle: "Clientes",
				subicon: (
					<SvgIcon
						color="primary"
						fontSize="small"
						component={iconCliente}
						inheritViewBox
						width={24}
						height={24}
					/>
				),
				sublink: "/clientes",
				subtype: "item",
				subrule: ["Admin"],
			},
			{
				key: "funcionarios",
				subtitle: "Funcionários",
				subicon: (
					<SvgIcon
						color="primary"
						fontSize="small"
						component={iconFuncionario}
						inheritViewBox
						width={24}
						height={24}
					/>
				),
				sublink: "/funcionarios",
				subtype: "item",
				subrule: ["Admin"],
			},
			{
				key: "fornecedor",
				subtitle: "Fornecedores",
				subicon: (
					<SvgIcon
						fontSize="small"
						component={IconFornecedor}
						inheritViewBox
						width={24}
						height={24}
					/>
				),
				subtype: "item",
				sublink: "/financeiro/fornecedor",
				subrule: ["Admin"],
			},
			{
				key: "esquadrias-projetos",
				subtitle: "Esquadrias e Projetos",
				subicon: (
					<SvgIcon
						fontSize="small"
						component={iconProjeto}
						inheritViewBox
						width={24}
						height={24}
					/>
				),
				sublink: "/cadastros/projetos",
				subrule: ["Admin", "Tecnico"],
				subtype: "item",
			},
			{
				key: "catalogos",
				subtitle: "Catalogos técnicos",
				subicon: (
					<SvgIcon
						fontSize="small"
						component={iconCatalagos}
						inheritViewBox
						width={24}
						height={24}
					/>
				),
				subtype: "group",
				subrule: ["Admin", "Tecnico"],
				submenus: [
					{
						key: "perfis",
						subtitle: "Perfis",
						subtype: "item",
						sublink: "/cadastros/perfis",
					},
					{
						key: "grupo-perfis-cores",
						subtitle: "Grupo de Preços e Acabamentos",
						subtype: "item",
						sublink: "/cadastros/grupos",
						sxsubtitle: {
							whiteSpace: "wrap",
						},
					},
					{
						key: "componentes",
						subtitle: "Componentes",
						subtype: "item",
						sublink: "/cadastros/componentes",
					},
					{
						key: "vidros",
						subtitle: "Vidros",
						subtype: "item",
						sublink: "/cadastros/vidros",
					},
					{
						key: "superficies",
						subtitle: "Superfícies",
						subtype: "item",
						sublink: "/cadastros/superficies",
					},
					{
						key: "kits",
						subtitle: "Kits",
						subtype: "item",
						sublink: "/cadastros/kits",
					},
					{
						key: "servicos",
						subtitle: "Serviços",
						subtype: "item",
						sublink: "/cadastros/servicos",
					},
				],
			},
		],
	},
	{
		key: "orcamentos",
		title: "Orçamentos",
		icon: (
			<SvgIcon
				fontSize="small"
				component={iconOrcamento}
				inheritViewBox
				width={24}
				height={24}
			/>
		),
		link: "/cadastros/orcamento",
		rule: ["Admin", "Financeiro", "Tecnico", "Vendedor", "Fiscal"],
		type: "item",
		module: [EnumModulePermissions.ORCAMENTO],
	},
	{
		key: "obras-producao",
		title: "Obras em produção",
		icon: (
			<SvgIcon
				fontSize="small"
				component={iconObrasProducao}
				inheritViewBox
				width={24}
				height={24}
			/>
		),
		link: "/obras/producao",
		rule: ["Admin", "Financeiro", "Tecnico", "Vendedor", "Producao", "Fiscal"],
		type: "item",
		module: [EnumModulePermissions.PRODUCAO],
	},
	{
		key: "romaneios",
		title: "Romaneios",
		icon: (
			<SvgIcon
				fontSize="small"
				component={iconRomaneio}
				inheritViewBox
				width={24}
				height={24}
			/>
		),
		link: "/romaneios",
		type: "item",
		rule: ["Admin", "Financeiro", "Tecnico", "Vendedor", "Fiscal"],
		hasDivider: true,
	},
	{
		key: "financeiro",
		title: "Financeiro",
		icon: (
			<SvgIcon
				fontSize="small"
				component={iconFinanceiro}
				inheritViewBox
				width={24}
				height={24}
			/>
		),
		hasDivider: true,
		type: "group",
		rule: ["Admin", "Financeiro", "Fiscal"],
		submenus: financeiroItems,
		module: [EnumModulePermissions.FINANCEIRO, EnumModulePermissions.FISCAL],
	},
	{
		key: "ordem-servico",
		title: "Ordem de serviço",
		icon: (
			<SvgIcon
				fontSize="small"
				component={iconOrdemServico}
				inheritViewBox
				width={24}
				height={24}
			/>
		),
		link: "/ordemServico",
		type: "item",
		rule: ["Admin", "Financeiro", "Tecnico", "Vendedor", "Fiscal", "Servico"],
		hasDivider: true,
		module: [EnumModulePermissions.SERVICOS],
	},
	{
		key: "tarefas",
		title: "Tarefas",
		icon: (
			<SvgIcon
				fontSize="small"
				component={iconTarefas}
				inheritViewBox
				width={24}
				height={24}
			/>
		),
		link: "/tarefas",
		type: "item",
		rule: ["Admin", "Financeiro", "Tecnico", "Vendedor", "Fiscal", "Servico"],
		module: [EnumModulePermissions.SERVICOS],
	},
	{
		key: "configuracoes",
		title: "Configurações",
		icon: (
			<SvgIcon
				fontSize="small"
				component={iconConfiguracao}
				inheritViewBox
				width={24}
				height={24}
			/>
		),
		link: "/gerenciar",
		type: "item",
	},
];

export function Sidebar(props: Props) {
	const {
		isOpenDrawer,
		handleIsOpenDrawer,
		isNavigateBlocked,
		handleLinkNavigateBlocked,
	} = React.useContext(SidebarContext);

	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const { isDesktop } = useResponsive();
	const navigate = useNavigate();

	const handleCloseMenuMobile = () => setMobileOpen(false);
	const handleOpenMenuMobile = () => setMobileOpen(true);

	const { user } = useAuth();

	const handleCloseMenu = (
		route: string | undefined,
		hasSubmenus?: boolean,
	) => {
		if (hasSubmenus || !route) return;

		if (!isNavigateBlocked) {
			if (isDesktop) {
				navigate(route);
			} else {
				handleCloseMenuMobile();

				setTimeout(() => {
					navigate(route);
				}, 100);
			}
		} else {
			handleLinkNavigateBlocked(route);
		}
	};

	const drawer = (
		<StackScroll
			py={3}
			flex={1}
			sx={{
				overflowX: "hidden",
			}}
		>
			<Box display="flex" flexDirection="column" flex={1}>
				<Box
					display="flex"
					alignItems="center"
					justifyContent={isOpenDrawer || mobileOpen ? "flex-start" : "center"}
					width={"100%"}
					pr={0}
					pb={2}
					pl={isOpenDrawer || mobileOpen ? 2 : 0}
				>
					<AvatarDrawer />

					<Typography
						variant="body2"
						color="primary.700"
						sx={
							isOpenDrawer || mobileOpen
								? {
										marginLeft: 2,
										paddingRight: 2,
										display: "flex",
										whiteSpace: "pre-wrap",
										maxHeight: "40px",
										width: "100%",
										opacity: 1,
										visibility: "visible",
										transition:
											"opacity 0.2s linear 0.2s, visibility 0s linear 0.2s",
								  }
								: {
										marginLeft: 0,
										paddingRight: 0,
										display: "flex",
										whiteSpace: "pre-wrap",
										maxHeight: "40px",
										width: "0",
										opacity: 0,
										visibility: "hidden",
										transition: "opacity 0.2s linear, visibility 0s",
								  }
						}
					>
						Olá, {user?.name}
					</Typography>

					{!isDesktop && (
						<Box
							display="flex"
							alignItems="center"
							justifyContent="center"
							sx={{
								marginLeft: "auto",
							}}
						>
							<Button onClick={handleCloseMenuMobile}>
								<Icon color="primary">close</Icon>
							</Button>
						</Box>
					)}
				</Box>

				<Box display="flex" flex={1} flexDirection="column">
					<Stack spacing={0.5}>
						{sidebarItems.map(
							({
								title,
								icon,
								type,
								submenus,
								link,
								hasDivider,
								rule,
								module,
								key,
							}) => {
								return (
									<AuthPermission key={key} module={module} rule={rule ?? []}>
										<MenuItem
											key={key}
											title={title}
											link={link}
											icon={icon}
											type={type}
											onClick={(route) => {
												handleCloseMenu(route, Boolean(submenus?.length));
											}}
											hasDivider={hasDivider}
											sx={{
												height: "44px",
											}}
											isOpenSidebar={isOpenDrawer || mobileOpen}
										>
											<>
												{submenus?.map(
													({
														subtitle,
														subtype,
														sublink,
														subicon,
														submodule,
														submenus: sub,
														key,
														subrule,
													}) => {
														return (
															<AuthPermission
																key={key}
																rule={subrule ?? []}
																module={submodule ?? []}
															>
																<MenuItem
																	key={key}
																	title={subtitle}
																	type={subtype}
																	link={sublink}
																	icon={subicon}
																	onClick={handleCloseMenu}
																	isOpenSidebar={isOpenDrawer || mobileOpen}
																>
																	<>
																		{sub?.map((subItem, index) => {
																			return (
																				<AuthPermission
																					key={index}
																					rule={subItem.subrule ?? []}
																					module={subItem.submodule ?? []}
																				>
																					<MenuItem
																						key={subItem.key}
																						title={subItem.subtitle}
																						type={subItem.subtype}
																						link={subItem.sublink}
																						icon={subItem.subicon}
																						sxTitle={subItem.sxsubtitle}
																						onClick={handleCloseMenu}
																						isOpenSidebar={
																							isOpenDrawer || mobileOpen
																						}
																					/>
																				</AuthPermission>
																			);
																		})}
																	</>
																</MenuItem>
															</AuthPermission>
														);
													},
												)}
											</>
										</MenuItem>
									</AuthPermission>
								);
							},
						)}
					</Stack>
				</Box>

				<Stack mx={2} pt={2}>
					<FooterAppVersion isOpenSidebar={isOpenDrawer || mobileOpen} />
				</Stack>
			</Box>
		</StackScroll>
	);

	return (
		<Box display="flex">
			<CssBaseline />
			<AppBar
				position="fixed"
				sx={{
					[theme.breakpoints.up("md")]: {
						display: "none",
					},
				}}
			>
				<Toolbar>
					<Box flexGrow={1}>
						<IconButton
							onClick={handleOpenMenuMobile}
							size="large"
							edge="start"
							color="inherit"
							aria-label="menu"
						>
							<Icon>menu</Icon>
						</IconButton>
					</Box>
				</Toolbar>
			</AppBar>

			<Drawer
				open={mobileOpen}
				onClose={handleCloseMenuMobile}
				ModalProps={{ keepMounted: true, hideBackdrop: true }}
				transitionDuration={{
					exit: 200,
				}}
				closeAfterTransition
				sx={{
					"& .MuiDrawer-paper": {
						boxSizing: "border-box",
						width: "90%",
					},
				}}
			>
				{drawer}
			</Drawer>

			{isDesktop && (
				<Box
					component="nav"
					sx={{
						width: theme.spacing(10.5),
						flexShrink: { sm: 0 },
					}}
				/>
			)}

			<DesktopMenuDrawer
				variant="permanent"
				open={isOpenDrawer}
				onMouseEnter={() => {
					handleIsOpenDrawer(true);
				}}
				onMouseLeave={() => {
					handleIsOpenDrawer(false);
				}}
			>
				{drawer}
			</DesktopMenuDrawer>

			<Container
				sx={{
					backgroundColor: "#EDEDED",
					mt: !isDesktop ? 8 : 0,
					ml: 0,
					mr: 0,
					py: 2,
					minHeight: "100vh",
					width: "100vw",
					maxWidth: "100vw !important",
				}}
			>
				{props.children}
			</Container>
		</Box>
	);
}
