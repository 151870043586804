import { useNavigation } from "contexts/notificationsContext";
import { usePagination } from "hooks/usePagination";
import { useState } from "react";
import { getListaCadastro } from "services/modules/pessoas";
import { GetListaPessoas, IParamsPessoas } from "shared/interfaces/pessoas";

export const SEARCH_BAR_PESSOA_ID = "search-bar-componente-anchor";

export function usePessoa() {
	const { openNotification } = useNavigation();
	const [pessoas, setPessoas] = useState<GetListaPessoas[]>([]);
	const [isLoadingData, setIsLoadingData] = useState(false);

	const {
		pagination,
		debouncedSearch,
		handlePagination,
		handleTotalItems,
		totalItems,
	} = usePagination({
		search: "",
		page: 1,
		pageSize: 10,
		direction: "asc",
		orderBy: "",
		totalItemsPage: 0,
	});

	const getPessoasData = async (params?: Partial<IParamsPessoas>) => {
		setIsLoadingData(true);
		try {
			const result = await getListaCadastro(params);

			if (result) {
				setPessoas(result.data);
				handleTotalItems(result.totalRecords);
				return;
			}
		} catch (error: any) {
			openNotification({
				isOpen: true,
				message: error?.message,
				type: "error",
			});
		} finally {
			setIsLoadingData(false);
		}
		setPessoas([]);
	};

	function handleUpdatePage(newPage: number) {
		handlePagination({ page: newPage });

		setTimeout(() => {
			const element = document.getElementById(SEARCH_BAR_PESSOA_ID);

			if (element) {
				element.scrollIntoView({
					behavior: "smooth",
				});
			}
		}, 200);
	}

	return {
		debouncedSearch,
		totalItems,
		handleUpdatePage,
		handlePagination,
		getPessoasData,
		pessoas,
		isLoadingData,
		pagination,
	};
}
