import {
	Add,
	CheckCircle,
	CheckCircleOutline,
	ChevronRight,
} from "@mui/icons-material";
import { Grid, Paper, Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { PessoasCard } from "components/cardResponsivo";
import { CustomButton } from "components/CustomButtom";
import { FloatMenuCrud } from "components/FloatMenuCrud";
import MobilePagination from "components/MobilePagination";
import { SearchBarOfTable } from "components/SearchBarOfTable";
import { StripedDataGrid } from "components/StripedDataGrid";
import { TitleTable } from "components/TitleTable";
import { useNavigation } from "contexts/notificationsContext";
import {
	SEARCH_BAR_PESSOA_ID,
	usePessoa,
} from "pages/Clientes/hooks/usePessoa";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { putDesativarPessoa } from "services/modules/pessoas";
import { useResponsive } from "shared/hooks/useResponsive";
import { EnumTipoPessoa, GetListaPessoas } from "shared/interfaces/pessoas";
import { convertFoneCell } from "shared/mask";
import theme from "styles/theme";

type HookParams = {
	onEdit(id: string | number): void;
	onRemove(id: string | number, ativo: boolean): void;
};

const getTipoPessoa = (id: number) => {
	switch (id) {
		case 1:
			return "Tecnico";

		case 2:
			return "Vendedor";

		case 3:
			return "Cliente";

		default:
			return "";
	}
};

const useUsuariosColumns = ({ onEdit, onRemove }: HookParams) => {
	const columns: GridColDef[] = [
		{
			field: "nome",
			headerName: "Nome",
			flex: 0.15,
		},
		{
			field: "funcao",
			headerName: "Função",
			flex: 0.1,
			renderCell: (params) => {
				const row: GetListaPessoas = params.row;
				return getTipoPessoa(row.idTipoPessoa);
			},
		},
		{
			field: "email",
			headerName: "E-mail",
			flex: 0.15,
		},
		{
			field: "telefone",
			headerName: "Telefone/celular",
			flex: 0.1,
			renderCell: (params) => {
				return convertFoneCell(params.formattedValue ?? "");
			},
		},
		{
			field: "usuario",
			headerName: "Usuário",
			sortable: false,
			flex: 0.05,
			renderCell: (params) => {
				return params.row.usuario ? (
					<CheckCircle
						color="success"
						sx={{ display: "flex", justifyContent: "center" }}
					/>
				) : (
					<CheckCircleOutline
						color="disabled"
						sx={{ display: "flex", justifyContent: "center" }}
					/>
				);
			},
		},
		{
			field: "ativo",
			headerName: "Ativo",
			sortable: false,
			flex: 0.05,
			renderCell: (params) => {
				return params.row.ativo ? (
					<CheckCircle
						color="success"
						sx={{ display: "flex", justifyContent: "center" }}
					/>
				) : (
					<CheckCircleOutline
						color="disabled"
						sx={{ display: "flex", justifyContent: "center" }}
					/>
				);
			},
		},
		{
			field: "options",
			sortable: false,
			headerName: "",
			align: "center",
			flex: 0.05,
			renderCell: (params) => {
				return (
					<FloatMenuCrud
						onEdit={() => onEdit(params.id)}
						showDuplicateOption={false}
						hasDelete={false}
						otherOptions={[
							{
								icon: <ChevronRight />,
								label: params.row.ativo ? "DESATIVAR" : "ATIVAR",
								onClickButton: () => onRemove(params.id, params.row.ativo),
								color: params.row.ativo ? "error" : "success",
							},
						]}
					/>
				);
			},
		},
	];
	return columns;
};

export const ListagemUsuarios: React.FC = () => {
	const { openNotification } = useNavigation();
	const navigate = useNavigate();
	const { isDesktop } = useResponsive();
	const [isLoadingDelete, setIsLoadingDelete] = useState(false);

	const {
		debouncedSearch,
		pessoas,
		pagination,
		totalItems,
		isLoadingData,
		handlePagination,
		handleUpdatePage,
		getPessoasData,
	} = usePessoa();

	const columns = useUsuariosColumns({
		onEdit(id) {
			navigate(`/gerenciar/usuarios/${id}`);
		},
		onRemove: onDesativarUsuarios,
	});

	async function onDesativarUsuarios(idsPessoas: number, ativo: boolean) {
		setIsLoadingDelete(true);
		try {
			const result = await putDesativarPessoa([idsPessoas]);
			if (result) {
				openNotification({
					isOpen: true,
					message: ativo
						? "Usuário desativado com sucesso!"
						: "Usuário ativado com sucesso!",
					type: "success",
				});
				getPessoasData({
					Filtrar: pagination.search,
					Page: pagination.page,
					Direction: pagination.direction,
					Limit: pagination.pageSize,
					SortBy: pagination.orderBy,
					idsTipoPessoa: [EnumTipoPessoa.VENDEDOR, EnumTipoPessoa.TECNICO],
				});
			}
		} catch (error: any) {
			openNotification({
				isOpen: true,
				message: error?.message,
				type: "error",
			});
		} finally {
			setIsLoadingDelete(false);
		}
	}

	useEffect(() => {
		getPessoasData({
			Filtrar: pagination.search,
			Page: pagination.page,
			Direction: pagination.direction,
			Limit: pagination.pageSize,
			SortBy: pagination.orderBy,
			idsTipoPessoa: [EnumTipoPessoa.VENDEDOR, EnumTipoPessoa.TECNICO],
		});
	}, [pagination]);

	return (
		<>
			<Grid container mb={4}>
				<Grid item xs={12} sm={8} md={12}>
					<SearchBarOfTable
						fullWidth={!isDesktop}
						maxWidth="auto"
						mrChildren={isDesktop ? 2 : 0}
						onChange={(event) => debouncedSearch(event.target.value)}
						id={SEARCH_BAR_PESSOA_ID}
						bgColor="gray_light"
						endAdornment={
							<CustomButton
								variant="contained"
								onClick={() => {
									navigate("/gerenciar/usuarios/cadastrar");
								}}
								endIcon={<Add />}
								sx={{
									[theme.breakpoints.up("md")]: {
										width: 240,
										alignSelf: "center",
									},
									[theme.breakpoints.down("md")]: {
										width: "100%",
									},
									ml: 2,
									height: "100%",
								}}
							>
								Cadastrar
							</CustomButton>
						}
					>
						<TitleTable title="Listagem de Usuários" />
					</SearchBarOfTable>
				</Grid>
			</Grid>
			<Paper>
				<Stack height={"calc(100vh - 280px)"}>
					<StripedDataGrid
						rows={pessoas}
						columns={columns}
						getRowId={(row: GetListaPessoas) => row.idPessoa}
						onClickDelete={(ids) => null}
						page={pagination.page - 1}
						pageSize={pagination.pageSize}
						rowCount={totalItems}
						loading={isLoadingData || isLoadingDelete}
						checkboxSelection={false}
						hideDeleteButton
						onPageChange={(newPage) => handlePagination({ page: newPage + 1 })}
						onPageSizeChange={(newPageSize) => {
							handlePagination({
								pageSize: newPageSize,
								page: 1,
							});
						}}
						autoHeight={false}
						rowHeight={70}
						onChangeSingleColumnOrder={(value) => {
							const sortBy = value.sortBy.replace(/-([a-z])/g, (g) =>
								g[1].toUpperCase(),
							);

							handlePagination({
								orderBy: sortBy,
								direction: value.direction,
								page: 1,
							});
						}}
						sx={{ display: !isDesktop ? "none" : "inherit" }}
					/>
				</Stack>
			</Paper>
			<Grid
				container
				spacing={3}
				sx={{
					display: {
						xs: "inherit",
						md: "none",
					},
				}}
			>
				{pessoas.map((item) => (
					<Grid key={item.idPessoa} item xs={12} sm={6}>
						<PessoasCard
							values={item}
							fields={{
								nome: "Usuários",
								telefone: "Telefone",
							}}
							keyExtractor={(values) => values?.idPessoa as number}
							buttonText="Editar Usuário"
							onPressButton={() =>
								navigate(`/clientes/editar/${item.idPessoa}`)
							}
						/>
					</Grid>
				))}

				<Grid item xs={12}>
					<MobilePagination
						page={pagination.page}
						pageSize={pagination.pageSize}
						totalItems={totalItems}
						onChangePage={handleUpdatePage}
					/>
				</Grid>
			</Grid>
		</>
	);
};
