import { api } from "services/axios/api";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import { parseParams } from "shared/helpers/parseParams";
import { ApiResponse } from "shared/interfaces/api";
import { ApiListResponse } from "shared/interfaces/meta";
import {
	GetIdPessoas,
	GetListaPessoas,
	IParamsPessoas,
} from "shared/interfaces/pessoas";

export const getListaCadastro = async (
	params?: Partial<IParamsPessoas>,
): Promise<ApiListResponse<GetListaPessoas> | null> => {
	try {
		const response = await api.get("/cadastros/pessoas", {
			params,
			paramsSerializer: (parametros) => parseParams(parametros),
		});
		return response.data;
	} catch (error: any) {
		getErrorsByApi(error, "Tivemos problemas internos ao buscar os vendedores");
	}
	return null;
};

export const getPessoasById = async (
	idPessoa: number,
): Promise<ApiResponse<GetIdPessoas> | null> => {
	try {
		const response = await api.get(`/cadastros/pessoas/${idPessoa}`);

		return response.data;
	} catch (error: any) {
		getErrorsByApi(error, "Tivemos problemas internos ao buscar os pessoas");
	}
	return null;
};

export const putDesativarPessoa = async (idsPessoas: number[]) => {
	if (!idsPessoas) throw new Error("Id da pessoa não informado");
	try {
		const response = await api.put(
			`/cadastros/pessoas/ativar-desativar?IdsPessoas=${idsPessoas[0]}`,
		);
		return response.data;
	} catch (error: any) {
		getErrorsByApi(error, "Tivemos problemas internos ao desativar a pessoa");
	}
	return null;
};
