import * as crypto from "crypto-js";

export function encrypt(data: string, key = "@painel-cemweb") {
	return crypto.AES.encrypt(JSON.stringify(data), key).toString();
}

export function decrypt(
	data: crypto.lib.CipherParams | string,
	key = "@painel-cemweb",
) {
	const bytes = crypto.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8);

	if (!bytes) return "";

	return JSON.parse(bytes);
}
