import {
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from "react-router-dom";

import { RequireAuth } from "./Route";

import Loading from "components/Loading";
import { useAuth } from "contexts/authContext";
import { GlobalContext } from "contexts/globalContext";
import { ProjetoProvider } from "contexts/projetoContext";
import { NotFound } from "pages/NotFound";
import { DadosOSProvider } from "pages/OrdemServico/hook/useDadosOS";
import { ListagemUsuarios } from "pages/Usuarios";
import { CadastrosDeUsuarios } from "pages/Usuarios/CadastrosDeUsuarios";
import { Suspense, useContext, useEffect } from "react";
import * as P from "./Pages";

const routesPublics = [
	"/",
	"/login",
	"/forgotpassword",
	"/newpassword",
	"/cadastrousuario",
];

export const RoutesComponent = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { tipoUsuario, token } = useAuth();
	const {
		permissionDashboard,
		permissionCatalogoTecnico,
		permissionCadastrarGrupoCorServico,
		permissionProjetos,
		permissionOrcamento,
		permissionUsuarios,
		permissionTarefas,
		permissionFinanceiro,
		permissionFiscal,
		permissionOrdemServico,
		permissionProducao,
		permissionRomaneio,
	} = useContext(GlobalContext);

	useEffect(() => {
		if (!token && !routesPublics.includes(location.pathname.toLowerCase())) {
			navigate("/login");
		}
	}, [token, tipoUsuario]);

	return (
		<Routes>
			{/* AUTH ROUTES */}
			<Route path="/" element={<Navigate to="/login" />} />
			<Route path="/login" element={<P.SignIn />} />

			<Route
				path="/forgotPassword"
				element={
					<Suspense fallback={<Loading isFixed />}>
						<P.ForgotPassword />
					</Suspense>
				}
			/>

			<Route path="/newPassword" element={<P.NewPassword />} />
			<Route path="/CadastroUsuario" element={<P.CadastroUsuario />} />

			{/* DASHBOARD ROUTES */}
			<>
				{permissionDashboard && (
					<Route
						path="/dashboard"
						element={
							<RequireAuth>
								<P.Dashboard />
							</RequireAuth>
						}
					/>
				)}
				{permissionCatalogoTecnico && (
					<>
						<Route
							path="/cadastros/perfis"
							element={
								<RequireAuth>
									<P.PerfilLista />
								</RequireAuth>
							}
						/>
						<Route
							path="/cadastros/perfis/novo"
							element={
								<RequireAuth>
									<P.PerfilFormulario />
								</RequireAuth>
							}
						/>
						<Route
							path="/cadastros/perfis/editar/:id"
							element={
								<RequireAuth>
									<P.PerfilFormulario />
								</RequireAuth>
							}
						/>
						{permissionCadastrarGrupoCorServico && (
							<>
								<Route
									path="/cadastros/servicos/novo"
									element={
										<RequireAuth>
											<P.ServicosFormulario />
										</RequireAuth>
									}
								/>
								<Route
									path="/cadastros/servicos/editar/:id"
									element={
										<RequireAuth>
											<P.ServicosFormulario />
										</RequireAuth>
									}
								/>
								<Route
									path="/cadastros/grupos/novo"
									element={
										<RequireAuth>
											<P.GrupoFormulario />
										</RequireAuth>
									}
								/>
								<Route
									path="/cadastros/grupos/editar/:id"
									element={
										<RequireAuth>
											<P.GrupoFormulario />
										</RequireAuth>
									}
								/>
								<Route
									path="/cadastros/cores/novo"
									element={
										<RequireAuth>
											<P.CoresFomulario />
										</RequireAuth>
									}
								/>
								<Route
									path="/cadastros/cores/editar/:id"
									element={
										<RequireAuth>
											<P.CoresFomulario />
										</RequireAuth>
									}
								/>
							</>
						)}
						<Route
							path="/cadastros/servicos"
							element={
								<RequireAuth>
									<P.ServicosLista />
								</RequireAuth>
							}
						/>
						<Route
							path="/cadastros/vidros"
							element={
								<RequireAuth>
									<P.VidrosLista />
								</RequireAuth>
							}
						/>
						<Route
							path="/cadastros/vidros/novo"
							element={
								<RequireAuth>
									<P.VidrosFormulario />
								</RequireAuth>
							}
						/>
						<Route
							path="/cadastros/vidros/editar/:id"
							element={
								<RequireAuth>
									<P.VidrosFormulario />
								</RequireAuth>
							}
						/>
						<Route
							path="/cadastros/grupos"
							element={
								<RequireAuth>
									<P.GruposLista />
								</RequireAuth>
							}
						/>

						<Route
							path="/cadastros/superficies"
							element={
								<RequireAuth>
									<P.SuperficiesLista />
								</RequireAuth>
							}
						/>
						<Route
							path="/cadastros/superficies/novo"
							element={
								<RequireAuth>
									<P.SuperficiesFormulario />
								</RequireAuth>
							}
						/>
						<Route
							path="/cadastros/superficies/editar/:id"
							element={
								<RequireAuth>
									<P.SuperficiesFormulario />
								</RequireAuth>
							}
						/>
						<Route
							path="/cadastros/componentes"
							element={
								<RequireAuth>
									<P.ComponentesLista />
								</RequireAuth>
							}
						/>
						<Route
							path="/cadastros/componentes/novo"
							element={
								<RequireAuth>
									<P.ComponentesFormulario />
								</RequireAuth>
							}
						/>
						<Route
							path="/cadastros/componentes/editar/:id"
							element={
								<RequireAuth>
									<P.ComponentesFormulario />
								</RequireAuth>
							}
						/>
						<Route
							path="/cadastros/kits"
							element={
								<RequireAuth>
									<P.KitsLista />
								</RequireAuth>
							}
						/>
						<Route
							path="/cadastros/kits/novo"
							element={
								<RequireAuth>
									<P.KitsFormulario />
								</RequireAuth>
							}
						/>
						<Route
							path="/cadastros/kits/editar/:id"
							element={
								<RequireAuth>
									<P.KitsFormulario />
								</RequireAuth>
							}
						/>
					</>
				)}
				{permissionProjetos && (
					<>
						<Route
							path="/cadastros/projetos"
							element={
								<RequireAuth>
									<P.ProjetosLista />
								</RequireAuth>
							}
						/>
						<Route
							path="/cadastros/projetos/novo"
							element={
								<ProjetoProvider>
									<RequireAuth>
										<P.ProjetoFormulario />
									</RequireAuth>
								</ProjetoProvider>
							}
						/>
						<Route
							path="/cadastros/projetos/editar/:id"
							element={
								<ProjetoProvider>
									<RequireAuth>
										<P.ProjetoFormulario />
									</RequireAuth>
								</ProjetoProvider>
							}
						/>
					</>
				)}
				{permissionOrcamento && (
					<>
						<Route
							path="/cadastros/orcamento"
							element={
								<RequireAuth>
									<P.ListaOrcamento />
								</RequireAuth>
							}
						/>
						<Route
							path="/cadastros/orcamento/novo"
							element={
								<RequireAuth>
									<P.OrcamentoFormulario />
								</RequireAuth>
							}
						/>
						<Route
							path="/cadastros/orcamento/editar/:id"
							element={
								<RequireAuth>
									<P.OrcamentoFormulario />
								</RequireAuth>
							}
						/>
					</>
				)}
				<Route
					path="/clientes"
					element={
						<RequireAuth>
							<P.Clientes />
						</RequireAuth>
					}
				/>
				<Route
					path="/clientes/CadastrarClientes"
					element={
						<RequireAuth>
							<P.CadastrarClientes />
						</RequireAuth>
					}
				/>
				<Route
					path="/clientes/editar/:id"
					element={
						<RequireAuth>
							<P.CadastrarClientes />
						</RequireAuth>
					}
				/>
				<Route
					path="/gerenciar"
					element={
						<RequireAuth>
							<P.GerenciarSistema />
						</RequireAuth>
					}
				/>
				{permissionUsuarios && (
					<>
						<Route
							path="/gerenciar/usuarios"
							element={
								<RequireAuth>
									<P.GerenciarSistema isUsuario>
										<ListagemUsuarios />
									</P.GerenciarSistema>
								</RequireAuth>
							}
						/>
						<Route
							path="/gerenciar/usuarios/cadastrar"
							element={
								<RequireAuth>
									<P.GerenciarSistema isUsuario>
										<CadastrosDeUsuarios />
									</P.GerenciarSistema>
								</RequireAuth>
							}
						/>
						<Route
							path="/gerenciar/usuarios/:id"
							element={
								<RequireAuth>
									<P.GerenciarSistema isUsuario>
										<CadastrosDeUsuarios />
									</P.GerenciarSistema>
								</RequireAuth>
							}
						/>
						<Route
							path="/funcionarios"
							element={
								<RequireAuth>
									<P.Funcionarios />
								</RequireAuth>
							}
						/>
						<Route
							path="/funcionarios/cadastro"
							element={
								<RequireAuth>
									<P.CadastrarFuncionarios />
								</RequireAuth>
							}
						/>
						<Route
							path="/funcionarios/editar/:id"
							element={
								<RequireAuth>
									<P.CadastrarFuncionarios />
								</RequireAuth>
							}
						/>
						<Route
							path="/financeiro/fornecedor"
							element={
								<RequireAuth>
									<P.Fornecedor />
								</RequireAuth>
							}
						/>
					</>
				)}
				{permissionTarefas && (
					<>
						<Route
							path="/tarefas"
							element={
								<RequireAuth>
									<P.Tarefas />
								</RequireAuth>
							}
						/>
					</>
				)}
				{permissionRomaneio && (
					<>
						<Route
							path="/romaneios"
							element={
								<RequireAuth>
									<P.Romaneios />
								</RequireAuth>
							}
						/>
						<Route
							path="/romaneio/novo"
							element={
								<RequireAuth>
									<P.NovoRomaneio />
								</RequireAuth>
							}
						/>
						<Route
							path="/romaneio/editar/:id"
							element={
								<RequireAuth>
									<P.NovoRomaneio />
								</RequireAuth>
							}
						/>
					</>
				)}
				{permissionProducao && (
					<Route
						path="/obras/producao"
						element={
							<RequireAuth>
								<P.ObrasProducao />
							</RequireAuth>
						}
					/>
				)}
				{permissionFinanceiro && (
					<>
						<Route
							path="/financeiro/contas-a-pagar"
							element={
								<RequireAuth>
									<P.ContasAPagar />
								</RequireAuth>
							}
						/>
						<Route
							path="/financeiro/contas-bancarias"
							element={
								<RequireAuth>
									<P.ContasBancarias />
								</RequireAuth>
							}
						/>
						{process.env.REACT_APP_AMBIENT === "qa" && (
							<>
								<Route
									path="/financeiro/dashboard"
									element={
										<RequireAuth>
											<P.DashboardFinanceiro />
										</RequireAuth>
									}
								/>
								<Route
									path="/financeiro/cedentes"
									element={
										<RequireAuth>
											<P.Cedentes />
										</RequireAuth>
									}
								/>
							</>
						)}
						<Route
							path="/financeiro/contas-a-receber"
							element={
								<RequireAuth>
									<P.ContasAReceber />
								</RequireAuth>
							}
						/>
						<Route
							path="/financeiro/dre"
							element={
								<RequireAuth>
									<P.Dre />
								</RequireAuth>
							}
						/>
					</>
				)}
				{permissionFiscal && (
					<>
						<Route
							path="/financeiro/listagem-nota-fiscal"
							element={
								<RequireAuth>
									<P.NotaFiscalListagem />
								</RequireAuth>
							}
						/>
						<Route
							path="/financeiro/nota-fiscal"
							element={
								<RequireAuth>
									<P.NotaFiscal />
								</RequireAuth>
							}
						/>
						<Route
							path="/financeiro/nota-fiscal/:id"
							element={
								<RequireAuth>
									<P.NotaFiscal />
								</RequireAuth>
							}
						/>
						<Route
							path="/financeiro/fiscal"
							element={
								<RequireAuth>
									<P.Fiscal />
								</RequireAuth>
							}
						/>
					</>
				)}
			</>

			{permissionOrdemServico && (
				<Route
					path="/ordemServico"
					element={
						<RequireAuth>
							<DadosOSProvider>
								<P.OrdemServico />
							</DadosOSProvider>
						</RequireAuth>
					}
				/>
			)}
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
};

export default RoutesComponent;
