import { Stack, Typography } from "@mui/material";
import FolderOffIcon from "@mui/icons-material/FolderOff";

export const NoRecordFound = () => {
	return (
		<Stack alignItems="center" justifyContent="center" mt={3}>
			<FolderOffIcon fontSize="large" />

			<Typography fontFamily="Inter" fontWeight={400} fontSize={16} mt={1}>
				Nenhum registro encontrado
			</Typography>
		</Stack>
	);
};
