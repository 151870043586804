import { Button, ButtonProps } from "@mui/material";
import { forwardRef } from "react";

export const CustomButton = forwardRef<HTMLButtonElement, ButtonProps>(
	(props, ref) => {
		return (
			<Button
				{...props}
				ref={ref}
				sx={{
					px: 4,
					py: 1,
					...props.sx,
				}}
			>
				{props.children}
			</Button>
		);
	},
);
CustomButton.displayName = "CustomButton";
