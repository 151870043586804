export function validarDocumento(value?: string) {
	if (value?.length) {
		/* eslint-disable no-useless-escape */
		const isMatch = value.match(
			/(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/,
		);

		return isMatch !== null;
	}
	return true;
}
