import { useAuthPermission } from "hooks/useAuthPermission";
import { ReactNode } from "react";
import {
	EnumModulePermissions,
	PermissionRules,
} from "shared/interfaces/authPermission";

interface AuthPermissionProps {
	children: ReactNode;
	module?: EnumModulePermissions | EnumModulePermissions[];
	rule: PermissionRules | PermissionRules[];
}

export function AuthPermission({
	children,
	module,
	rule,
}: AuthPermissionProps) {
	const useCanSeeComponent = useAuthPermission({ module, rule });

	if (!useCanSeeComponent) {
		return null;
	}

	return <>{children}</>;
}
