import {
	CircularProgress,
	FilledTextFieldProps,
	IconButton,
	InputAdornment,
	TextField as MuiTextField,
	TextFieldProps as MuiTextFieldProps,
	Stack,
	Tooltip,
} from "@mui/material";
import * as React from "react";
import { Icon } from "@iconify/react";
import CustomIcon from "@mui/material/Icon";
import { styled } from "@mui/material/styles";
import { maskfy, MaskTypes } from "shared/mask";
import InputPopover from "./InputPopover";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { ControllerRenderProps, FieldValues } from "react-hook-form";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { fontsSizes } from "styles/theme";
import "dayjs/locale/pt-br";
import dayjs, { Dayjs } from "dayjs";
import { IconData } from "./ControlledTextField";

const IS_NUMERIC: MaskTypes[] = [
	"decimal",
	"percentSuffix",
	"date",
	"foneCell",
	"money",
	"discount",
	"weight",
	"numberAccount",
	"only-numbers",
	"cpfCnpj",
	"cep",
];

export type TextFieldProps = {
	withoutHelperText?: boolean;
	name?: string;
	maxLength?: number;
	customIcon?: string;
	iconColor?: string;
	mask?: MaskTypes;
	onClickIcon?: () => void;
	uppercase?: boolean;
	withFloatInput?: boolean;
	numericFormatProps?: NumericFormatProps;
	field?: ControllerRenderProps<FieldValues, string>;
	onlyDisabledInput?: boolean;
	upTo100?: boolean;
	loading?: boolean;
	minDate?: Dayjs;
	maxDate?: Dayjs;
	startAdornment?: React.ReactNode;
	showZero?: boolean;
	customEndAdornment?: React.ReactNode;
	customIcons?: IconData[];
	shouldDisableDate?: (day: any) => boolean;
	layoutDataPicker?: any;
	decimalScale?: number;
} & MuiTextFieldProps;

export const styleFullHeight = {
	"&.MuiFormControl-root, & .MuiFilledInput-root": {
		height: "100%",
	},
};

export const styleInput = {
	"& .MuiFilledInput-root": {
		backgroundColor: "#EDEDED",
		borderRadius: "4px",
		fontSize: fontsSizes.medium,
	},
	"& .MuiInputLabel-root": {
		fontSize: fontsSizes.medium,
	},
	"& .MuiFormHelperText-root": {
		fontSize: fontsSizes.small,
	},
	"& label.MuiInputLabel-root.Mui-focused": {
		color: "#005C8F",
	},
	"& .MuiFilledInput-root.Mui-disabled": {
		backgroundColor: "#dcdddd",
	},
	"& .MuiFormLabel-root.MuiInputLabel-root": {
		transform: "translate(12px, 18px) scale(1)",
	},
	"& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink": {
		transform: "translate(12px, 7px) scale(0.75)",
	},
};

export const CustomTextField = styled(MuiTextField, {
	shouldForwardProp: (propName) =>
		propName !== "loading" && propName !== "customEndAdornment",
})((props) => {
	if (props.style) {
		return {
			borderRadius: "4px",
			backgroundColor: "#EDEDED !important",
			"& .MuiFilledInput-root": {
				backgroundColor: props.style.backgroundColor
					? props.style.backgroundColor
					: "#EDEDED",
				borderRadius: "4px",
			},
			"& .MuiFormHelperText-root": {
				backgroundColor: "#EDEDED",
				color: "#005C8F",
			},
			"& .MuiInputLabel-root": {
				fontSize: fontsSizes.medium,
			},
			"& .MuiFormLabel-root.MuiInputLabel-root": {
				transform: "translate(12px, 18px) scale(1)",
			},
			"& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink": {
				transform: "translate(12px, 7px) scale(0.75)",
			},
		};
	}

	return styleInput;
});

const getCustomInput = (
	mask: MaskTypes | undefined,
	props: any,
	field: ControllerRenderProps<FieldValues, string> | undefined,
	numericFormatProps?: NumericFormatProps,
	upTo100?: boolean,
	decimalScale?: number,
) => {
	switch (mask) {
		case "decimal": {
			return (
				<NumericFormat
					{...props}
					defaultValue={""}
					fixedDecimalScale={false}
					thousandSeparator="."
					decimalSeparator=","
					name={field?.name}
					value={field?.value ?? ""}
					onValueChange={({ floatValue }) => {
						field?.onChange(floatValue);
					}}
					onBlur={field?.onBlur}
					variant="filled"
					label={props.label}
					customInput={CustomTextFieldInput}
					decimalScale={decimalScale ?? 2}
				/>
			);
		}
		case "percentSuffix": {
			return (
				<NumericFormat
					{...props}
					fixedDecimalScale={false}
					thousandSeparator="."
					decimalSeparator=","
					name={field?.name}
					value={field?.value ?? ""}
					onValueChange={({ floatValue }) => {
						field?.onChange(floatValue ?? 0);
					}}
					onBlur={(event) => {
						field?.onBlur();
						props.onBlur?.(event);
					}}
					variant="filled"
					label={props.label}
					customInput={CustomTextFieldInput}
					suffix="%"
					isAllowed={({ floatValue, formattedValue }) => {
						const maxValue = upTo100 ? 100 : 999;
						const regex = /^\d+(\.\d{1,2})?$/;
						const regexEdit = /^\d{0,1}$/;
						if (floatValue === 0) {
							return regexEdit.test(formattedValue.replace("%", ""));
						}
						if (floatValue !== undefined) {
							return (
								floatValue >= 0 &&
								floatValue <= maxValue &&
								regex.test(floatValue?.toString() || "")
							);
						}
						return formattedValue === "";
					}}
					max={upTo100 ? 100 : 999}
					min={0}
				/>
			);
		}
		case "custom": {
			return (
				<NumericFormat
					{...props}
					name={field?.name}
					value={field?.value ?? ""}
					onValueChange={({ floatValue }) => {
						field?.onChange(floatValue);
					}}
					onBlur={field?.onBlur}
					variant="filled"
					label={props.label}
					customInput={CustomTextFieldInput}
					{...numericFormatProps}
				/>
			);
		}
		case "date": {
			return (
				<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
					<DatePicker
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-notchedOutline": {
								border: "none",
								borderColor: "transparent",
							},
							"& .MuiOutlinedInput-root": {
								backgroundColor: "#EDEDED",
								borderRadius: "4px",
								fontSize: fontsSizes.medium,
							},
							"& .MuiInputBase-input.MuiOutlinedInput-input": {
								paddingTop: "25px",
								paddingRight: "12px",
								paddingBottom: "8px",
								paddingLeft: "12px",
							},
							"& .MuiOutlinedInput-root.Mui-disabled": {
								backgroundColor: "#dcdddd",
							},
							...styleInput,
							"& .MuiFormHelperText-root": {
								color: "error.main",
								fontSize: fontsSizes.small,
							},
							"& .MuiFormLabel-root.MuiInputLabel-root.Mui-error": {
								color: props.error ? "error.main" : "text.secondary",
							},
							"& .MuiFormLabel-root.MuiInputLabel-root": {
								color: props.error ? "error.main" : "text.secondary",
							},
						}}
						minDate={props.minDate}
						maxDate={props.maxDate}
						label={props.label}
						format="DD/MM/YYYY"
						disabled={props.disabled}
						onChange={(date: any) => {
							field?.onChange(date ? dayjs(date).format("YYYY-MM-DD") : "");
							field?.onBlur();
						}}
						shouldDisableDate={props.shouldDisableDate}
						value={dayjs(field?.value ?? "")}
						slotProps={{
							textField: {
								helperText: props.helperText,
							},
							layout: props.layoutDataPicker,
						}}
					/>
				</LocalizationProvider>
			);
		}
		default: {
			return null;
		}
	}
};

const getInputMode = (mask?: MaskTypes) => {
	if (IS_NUMERIC.includes(mask ?? "custom")) {
		return "numeric";
	}

	return "text";
};

const TextField: React.FC<TextFieldProps> = ({
	type = "text",
	maxLength,
	customIcon,
	customIcons,
	iconColor = "#747474",
	withoutHelperText = false,
	helperText,
	value = "",
	mask,
	uppercase,
	onClickIcon,
	withFloatInput = false,
	field,
	numericFormatProps,
	onlyDisabledInput = false,
	upTo100,
	loading,
	showZero,
	decimalScale,
	...props
}) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
	const inputRef = React.useRef<HTMLInputElement>(null);

	const [showPassword, setShowPassword] = React.useState(false);

	const handleClick = () => {
		if (type === "password") {
			return setShowPassword((prev) => !prev);
		} else {
			onClickIcon?.();
		}
	};

	const handleClose = () => {
		setAnchorEl(null);

		setTimeout(() => {
			inputRef.current?.blur();
		}, 200);
	};

	const handleDoubleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if (event.detail === 2 && withFloatInput && !props.disabled) {
			setAnchorEl(event.currentTarget);
		}
	};

	return (
		<React.Fragment>
			<InputPopover
				anchor={anchorEl}
				onClose={handleClose}
				label={props?.label ?? ""}
				value={field?.value as string}
				onChange={(event) => {
					field?.onChange(event);
					props.onChange?.(event);
				}}
				uppercase={uppercase}
				mask={mask}
			/>

			{getCustomInput(
				mask,
				{
					helperText,
					loading,
					...props,
				},
				field,
				numericFormatProps,
				upTo100,
				decimalScale,
			) ?? (
				<CustomTextField
					{...props}
					disabled={onlyDisabledInput || props.disabled}
					sx={{ background: "#fff", ...props.sx }}
					name={field?.name}
					inputRef={inputRef}
					fullWidth
					variant="filled"
					value={
						value && !field
							? value
							: maskfy(
									showZero
										? String(field?.value ?? "")
										: uppercase
										? String(field?.value || "").toUpperCase()
										: String(field?.value || ""),
									mask,
							  )
					}
					title={
						value && !field
							? value.toString()
							: maskfy(
									showZero
										? String(field?.value ?? "")
										: uppercase
										? String(field?.value || "").toUpperCase()
										: String(field?.value || ""),
									mask,
							  )
					}
					onFocus={props.onFocus}
					onBlur={(event) => {
						field?.onBlur();
						props.onBlur?.(event);
					}}
					onChange={(event) => {
						field?.onChange(event);
						props.onChange?.(event);
					}}
					type={
						type === "password" ? (showPassword ? "text" : "password") : type
					}
					helperText={!withoutHelperText ? helperText : ""}
					inputProps={{
						maxLength: maxLength,
						inputMode: getInputMode(mask),
						...props.inputProps,
					}}
					InputProps={{
						disableUnderline: true,
						onClick: handleDoubleClick,
						endAdornment: (type === "password" ||
							!!customIcons ||
							!!customIcon ||
							loading ||
							props.customEndAdornment) && (
							<>
								{(type === "password" || !!customIcon) && (
									<InputAdornment
										position="end"
										sx={{
											width: "24px",
										}}
									>
										<IconButton
											onClick={() => handleClick()}
											disabled={props.disabled}
											sx={{
												padding: "4px",
											}}
										>
											{type === "password" &&
												(showPassword ? (
													<Icon icon="eva:eye-fill" />
												) : (
													<Icon icon="eva:eye-off-fill" />
												))}

											{customIcon && (
												<CustomIcon sx={{ color: iconColor }}>
													{customIcon}
												</CustomIcon>
											)}
										</IconButton>
									</InputAdornment>
								)}

								{loading && (
									<InputAdornment position="end">
										<CircularProgress color="primary" size={15} />
									</InputAdornment>
								)}

								{props.customEndAdornment}

								{customIcons && (
									<InputAdornment position="end">
										<Stack display={"flex"} flexDirection={"row"} mr={"-8px"}>
											{customIcons.map((iconData, index) => (
												<Tooltip key={index} title={iconData.toolTip}>
													<span>
														<IconButton
															onClick={iconData.onClick}
															disabled={props.disabled || iconData.disabled}
															sx={{
																padding: "3px",
																borderRadius: "50%",
																backgroundColor: "white",
															}}
														>
															<CustomIcon
																sx={{
																	color: iconData.disabled
																		? "GrayText"
																		: "primary.main",
																}}
															>
																{iconData.name}
															</CustomIcon>
														</IconButton>
													</span>
												</Tooltip>
											))}
										</Stack>
									</InputAdornment>
								)}
							</>
						),
						startAdornment: props.startAdornment,
						...props.InputProps,
					}}
				/>
			)}
		</React.Fragment>
	);
};

export default TextField;

interface CustomTextFieldInputProps extends FilledTextFieldProps {
	loading?: boolean;
}

const CustomTextFieldInput: (
	propsCustom: CustomTextFieldInputProps,
) => JSX.Element = (propsCustom) => {
	return (
		<CustomTextField
			fullWidth
			InputProps={{
				disableUnderline: true,
				endAdornment: Boolean(propsCustom.loading) && (
					<InputAdornment position="end">
						<CircularProgress color="primary" size={15} />
					</InputAdornment>
				),
				...propsCustom.InputProps,
			}}
			{...propsCustom}
		/>
	);
};
