import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Typography,
} from "@mui/material";
import { ContainerYesNoButtons } from "components/ContainerYesNoButtons";
import { KitItemList } from "pages/Projetos/components/ListaKitProjeto";
import { SuperficieItemList } from "pages/Projetos/components/ListaSuperficieProjeto";
import { VidroItemList } from "pages/Projetos/components/ListaVidroProjeto";
import { ReactNode, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import gridProjetoSubject from "./GridProjetoSubject";

interface ProjetoProviderProps {
	children: ReactNode;
}

export type ProjetoDetalheTipo =
	| "COMPONENTE"
	| "PERFIL"
	| "VIDRO"
	| "SUPERFICIE"
	| "KIT";

export type ProjectContextItem = {
	index: number;
};

interface ProjetoContextProps {
	hasUnsaved: boolean;
	putItem<T = any>(newItem: T, tipoDetalhe: ProjetoDetalheTipo): void;
	addItem<T = any>(item: T, tipoDetalhe: ProjetoDetalheTipo): void;
	removeItem: (key: number, tipoDetalhe: ProjetoDetalheTipo) => void;
	saveAll: (idProjeto: string) => void;
	removeChange: () => void;
	hasUnsavedLinesAndShowModal: (tipo: ProjetoDetalheTipo) => boolean;
}

export const ProjetoContext = createContext({} as ProjetoContextProps);

export const ProjetoProvider = ({ children }: ProjetoProviderProps) => {
	const [perfis, setPerfis] = useState<ProjectContextItem[]>([]);
	const [componentes, setComponentes] = useState<ProjectContextItem[]>([]);
	const [vidros, setVidros] = useState<VidroItemList[]>([]);
	const [superficies, setSuperficies] = useState<SuperficieItemList[]>([]);
	const [kits, setKits] = useState<KitItemList[]>([]);

	const [openUnsavedLines, setOpenUnsavedLines] = useState(false);
	const [focusedGrid, setFocusedGrid] = useState<ProjetoDetalheTipo | null>(
		null,
	);

	const addItem = (item: any, tipoDetalhe: ProjetoDetalheTipo) => {
		if (tipoDetalhe === "COMPONENTE") {
			setComponentes([...componentes, item]);
		}
		if (tipoDetalhe === "PERFIL") {
			setPerfis([...perfis, item]);
		}
		if (tipoDetalhe === "VIDRO") {
			setVidros([...vidros, item]);
		}
		if (tipoDetalhe === "SUPERFICIE") {
			setSuperficies([...superficies, item]);
		}
		if (tipoDetalhe === "KIT") {
			setKits([...kits, item]);
		}
	};

	const putItem = (newItem: any, tipoDetalhe: ProjetoDetalheTipo) => {
		if (tipoDetalhe === "COMPONENTE") {
			const hasItem = componentes.filter((c) => c.index !== newItem.index);

			setComponentes([...hasItem, newItem]);
		}
		if (tipoDetalhe === "PERFIL") {
			const hasItem = perfis.filter((p) => p.index !== newItem.index);
			setPerfis([...hasItem, newItem]);
		}
		if (tipoDetalhe === "VIDRO") {
			const hasItem = vidros.filter((v) => v.index !== newItem.index);

			setVidros([...hasItem, newItem]);
		}
		if (tipoDetalhe === "SUPERFICIE") {
			const hasItem = superficies.filter((s) => s.index !== newItem.index);

			setSuperficies([...hasItem, newItem]);
		}

		if (tipoDetalhe === "KIT") {
			const hasItem = kits.filter((k) => k.index !== newItem.index);

			setKits([...hasItem, newItem]);
		}
	};

	const removeItem = (key: number, tipoDetalhe: ProjetoDetalheTipo) => {
		if (tipoDetalhe === "COMPONENTE") {
			setComponentes(componentes.filter((c) => c.index !== key));
		}
		if (tipoDetalhe === "PERFIL") {
			setPerfis(perfis.filter((p) => p.index !== key));
		}
		if (tipoDetalhe === "VIDRO") {
			setVidros(vidros.filter((v) => v.index !== key));
		}
		if (tipoDetalhe === "SUPERFICIE") {
			setSuperficies(superficies.filter((s) => s.index !== key));
		}
		if (tipoDetalhe === "KIT") {
			setKits(kits.filter((s) => s.index !== key));
		}
	};

	const saveAll = async () => {
		if (componentes.length > 0) saveAllComponents();
		if (perfis.length > 0) saveAllProfiles();
		if (vidros.length > 0) saveAllGlass();
		if (superficies.length > 0) saveAllSurfaces();
		if (kits.length > 0) saveAllKits();
	};

	async function saveAllProfiles() {
		gridProjetoSubject.notify("SAVE_ALL_PROFILES", perfis);
		setPerfis([]);
	}

	async function saveAllComponents() {
		gridProjetoSubject.notify("SAVE_ALL_COMPONENTS", componentes);
		setComponentes([]);
	}

	async function saveAllGlass() {
		gridProjetoSubject.notify("SAVE_ALL_GLASS", vidros);
		setVidros([]);
	}

	async function saveAllSurfaces() {
		gridProjetoSubject.notify("SAVE_ALL_SURFACES", superficies);
		setSuperficies([]);
	}

	async function saveAllKits() {
		gridProjetoSubject.notify("SAVE_ALL_KITS", kits);
		setKits([]);
	}

	const removeChange = () => {
		setComponentes([]);
		setPerfis([]);
		setVidros([]);
		setSuperficies([]);
		setKits([]);
	};

	function hasUnsavedLinesAndShowModal(type: ProjetoDetalheTipo) {
		const lengths = {
			COMPONENTE: componentes.length,
			PERFIL: perfis.length,
			VIDRO: vidros.length,
			SUPERFICIE: superficies.length,
			KIT: kits.length,
		};

		const quantity = lengths[type] ?? 0;

		if (quantity > 1) {
			setFocusedGrid(type);
			setOpenUnsavedLines(true);
			return true;
		}

		return false;
	}

	async function handleSaveFocusedGrid() {
		if (!focusedGrid) return handleCloseUnsavedFocusedGrid();

		const services = {
			COMPONENTE: saveAllComponents,
			PERFIL: saveAllProfiles,
			VIDRO: saveAllGlass,
			SUPERFICIE: saveAllSurfaces,
			KIT: saveAllKits,
		};

		services[focusedGrid]();

		handleCloseUnsavedFocusedGrid();
	}

	function handleCloseUnsavedFocusedGrid() {
		setFocusedGrid(null);
		setOpenUnsavedLines(false);
	}

	return (
		<ProjetoContext.Provider
			value={{
				hasUnsaved:
					perfis.length > 0 ||
					componentes.length > 0 ||
					vidros.length > 0 ||
					superficies.length > 0 ||
					kits.length > 0,
				putItem,
				addItem,
				removeItem,
				saveAll,
				removeChange,
				hasUnsavedLinesAndShowModal,
			}}
		>
			<Dialog
				open={openUnsavedLines}
				onClose={() => setOpenUnsavedLines(false)}
				aria-describedby="unsaved-lines-dialog"
			>
				<DialogContent>
					<DialogContentText id="unsaved-lines-dialog">
						<Typography variant="subtitle1" color="black">
							Deseja salvar todos os itens em edição?
						</Typography>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<ContainerYesNoButtons
						onNo={handleCloseUnsavedFocusedGrid}
						onYes={handleSaveFocusedGrid}
					/>
				</DialogActions>
			</Dialog>

			{children}
		</ProjetoContext.Provider>
	);
};

export const useProjeto = (): ProjetoContextProps => {
	const hasUnsaved = useContextSelector(
		ProjetoContext,
		(projeto) => projeto.hasUnsaved,
	);
	const putItem = useContextSelector(
		ProjetoContext,
		(projeto) => projeto.putItem,
	);
	const addItem = useContextSelector(
		ProjetoContext,
		(projeto) => projeto.addItem,
	);
	const removeItem = useContextSelector(
		ProjetoContext,
		(projeto) => projeto.removeItem,
	);
	const saveAll = useContextSelector(
		ProjetoContext,
		(projeto) => projeto.saveAll,
	);

	const removeChange = useContextSelector(
		ProjetoContext,
		(projeto) => projeto.removeChange,
	);

	const hasUnsavedLinesAndShowModal = useContextSelector(
		ProjetoContext,
		(projeto) => projeto.hasUnsavedLinesAndShowModal,
	);

	return {
		hasUnsaved,
		putItem,
		addItem,
		removeItem,
		saveAll,
		removeChange,
		hasUnsavedLinesAndShowModal,
	};
};
