import { Box, Button, Divider, Typography } from "@mui/material";
import { ContainerYesNoButtons } from "components/ContainerYesNoButtons";
import ModalComponent from "components/Modal";

interface ModalDesativarUsuarioProps {
	isOpen: boolean;
	onClose: () => void;
	naoDesativar: () => void;
}

interface ModalEmailEnviadoProps {
	isOpen: boolean;
	onClose: () => void;
}

export function ModalDesativarUsuario({
	isOpen,
	onClose,
	naoDesativar,
}: ModalDesativarUsuarioProps) {
	return (
		<ModalComponent
			open={isOpen}
			handleClose={naoDesativar}
			width={{ md: "30%", sm: "50%", xs: "90%" }}
			height="auto"
			title="DESATIVAR USUÁRIO"
		>
			<Box px={3} py={2}>
				<Typography fontSize="1.2rem" mb={6} color="GrayText">
					Se o Usuário for desativado, todas os dados ficarão bloqueadas. Deseja
					continuar?
				</Typography>
			</Box>
			<ContainerYesNoButtons onNo={naoDesativar} onYes={onClose} />
		</ModalComponent>
	);
}

export function ModalEmailEnviado({ isOpen, onClose }: ModalEmailEnviadoProps) {
	return (
		<ModalComponent
			open={isOpen}
			handleClose={onClose}
			width={{ md: "30%", sm: "50%", xs: "90%" }}
			height="auto"
			title="E-mail enviado"
		>
			<Box px={3} py={2}>
				<Typography mb={6} color="GrayText">
					O e-mail para finalizar a autenticação foi enviado.
				</Typography>
			</Box>
			<Divider />
			<Box display="flex" justifyContent="flex-end" p={2}>
				<Button variant="contained" onClick={onClose}>
					FECHAR
				</Button>
			</Box>
		</ModalComponent>
	);
}
