import DesenhadorController from "components/DesenhadorController";
import Loading from "components/Loading";
import { Sidebar } from "components/Sidebar";
import { useAuth } from "contexts/authContext";
import { DesenhadorProvider } from "contexts/desenhadorContext";
import { SidebarProvider } from "contexts/sidebarContext";
import * as React from "react";
import { Navigate, useLocation } from "react-router";
import { setAuthorization } from "services/axios/api";

type RequiredAuthProps = {
	children?: React.ReactNode;
	withoutSideBar?: boolean;
};

export const RequireAuth: React.FC<RequiredAuthProps> = ({
	children,
	withoutSideBar = false,
}) => {
	const { token, validateSession } = useAuth();
	const location = useLocation();

	async function isValid() {
		return await validateSession();
	}

	if (!token || !isValid) {
		setAuthorization("");
		return <Navigate to="/" state={{ from: location }} replace />;
	}

	setAuthorization(token);

	if (withoutSideBar) return <>{children}</>;

	return (
		<SidebarProvider>
			<DesenhadorProvider>
				<>
					<Sidebar>
						<React.Suspense fallback={<Loading isFixed />}>
							{children}
						</React.Suspense>
					</Sidebar>
					<DesenhadorController />
				</>
			</DesenhadorProvider>
		</SidebarProvider>
	);
};
