import { useState, createContext } from "react";

export type SidebarContextProvider = {
	children: JSX.Element;
};

export type SidebarContextValues = {
	isOpenDrawer: boolean;
	handleIsOpenDrawer: (isOpenDrawer: boolean) => void;
	isNavigateBlocked: boolean;
	handleIsNavigateBlocked: (isNavigateBlocked: boolean) => void;
	linkNavigateBlocked: string;
	handleLinkNavigateBlocked: (linkBlocked: string) => void;
};

export const SidebarContext = createContext({} as SidebarContextValues);

export const SidebarProvider = ({ children }: SidebarContextProvider) => {
	const [isOpenDrawer, setIsOpenDrawer] = useState(false);
	const [isNavigateBlocked, setIsNavigateBlocked] = useState(false);
	const [linkNavigateBlocked, setLinkNavigateBlocked] = useState("");

	const handleIsOpenDrawer = (isOpenDrawer: boolean) => {
		setIsOpenDrawer(isOpenDrawer);
	};

	const handleIsNavigateBlocked = (isNavigateBlocked: boolean) => {
		setIsNavigateBlocked(isNavigateBlocked);
	};

	const handleLinkNavigateBlocked = (linkBlocked: string) => {
		setLinkNavigateBlocked(linkBlocked);
	};

	return (
		<SidebarContext.Provider
			value={{
				isOpenDrawer,
				handleIsOpenDrawer,
				isNavigateBlocked,
				handleIsNavigateBlocked,
				linkNavigateBlocked,
				handleLinkNavigateBlocked,
			}}
		>
			{children}
		</SidebarContext.Provider>
	);
};
