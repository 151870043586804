import { FilledInput, styled } from "@mui/material";
import { fontsSizes } from "styles/theme";

type CustomFilledInputProps = {
	bgColor: SearchBarColorVariants;
};

export type SearchBarColorVariants = keyof typeof colors;

export const colors = {
	gray_light: "#EDEDED",
	gray_dark: "#DDDDDD",
	white: "#FFFFFF",
};

const placeholderColors = {
	gray_light: "#939393",
	gray_dark: "#898989",
	white: "#898989",
};

export const CustomFilledInput = styled(FilledInput, {
	shouldForwardProp: (propName) => propName !== "bgColor",
})<CustomFilledInputProps>(({ bgColor }) => ({
	"& .MuiInputBase-input::placeholder": {
		color: placeholderColors[bgColor],
		opacity: 1,
	},
	"& .MuiInputBase-input": {
		fontSize: fontsSizes.medium,
		padding: 14,
		color: placeholderColors[bgColor],
	},
	"&.MuiFilledInput-root": {
		backgroundColor: colors[bgColor],
	},
}));
