import { useEffect, useState } from "react";

export type usePersistStateProps<S> = [state: S, setState: React.Dispatch<S>];

export function usePersistState<S>(
	key: string,
	initialValue: S,
): usePersistStateProps<S> {
	const [state, setState] = useState<S>(() => {
		const storageValue = sessionStorage.getItem(key);

		return storageValue ? JSON.parse(storageValue) : initialValue;
	});

	useEffect(() => {
		sessionStorage.setItem(key, JSON.stringify(state));
	}, [state, key]);

	return [state, setState];
}
