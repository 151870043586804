import { CommonQueryParams } from "./api";

export interface IPessoa {
	idPessoa: number;
	idCliente: number;
	idTipoPessoa: number;
	nome: string;
	documento: number;
	idEstado: string | number;
	idCidade: string | number;
	urlIcone: string | null;
	urlImagem: string | null;
}

export type IClientDadosId = {
	idCliente: number;
	nome: string;
	documento: string | null;
	endereco: string | null;
	cep: string | number | null;
	bairro: string | null;
	idCidade: number | undefined;
	idEstado: number | null;
	telefone: string | null;
	numero: number | null;
	complemento: string | null;
	email: string;
	ativo: boolean;
};

export interface VendedorOrcamentoForm {
	idTipoPessoaVendedor?: number;
	idVendedor?: number;
	vendedorNome: string;
	vendedorDocumento: string;
	vendedorEndereco: string;
	vendedorCep: string;
	vendedorBairro: string;
	vendedorIdCidade?: number | null;
	vendedorIdEstado?: number | null;
	vendedorNumero?: string;
	vendedorComplemento: string;
	vendedorEmail: string;
	vendedorTelefone: string;
}

export type GetVendedorApiResponse = {
	idPessoa: number;
	idTipoPessoa: number;
	nome: string;
	documento: string;
	endereco: string;
	cep: string;
	bairro: string;
	idCidade: string | number;
	idEstado: string | number;
	numero: string;
	complemento: string;
	email: string;
	telefone: string;
	ativo: boolean;
};

export type IPutVendedorParams = {
	idVendedor: number | null;
	nome: string | null;
	documento: string | null;
	endereco: string | null;
	cep: string | number | null;
	bairro: string | null;
	idCidade: number | undefined | null;
	idEstado: number | undefined | null;
	numero: number | null | string;
	complemento: string | null;
	email: string | null;
	telefone: string | null;
};

export type IVendedorParams = {
	idOrcamento: number | null;
	idVendedor: number | null;
	nome: string | null;
	documento: string | null;
	endereco: string | null;
	cep: string | number | null;
	bairro: string | null;
	idCidade: number | undefined;
	idEstado: number | null;
	telefone: string | null;
	numero: number | null | string;
	complemento: string | null;
	email: string | null;
};

export type IUpdateVendedorApiResponse = {
	data: { idVendedor: number };
	statusCode: number;
	success: boolean;
};

export interface CadastroClienteFormData {
	clienteNome: string;
	clienteDocumento: string;
	clienteCep: string;
	clienteEndereco: string;
	clienteNumero: string;
	clienteIdEstado?: number | null;
	clienteIdCidade?: number | null;
	clienteBairro: string;
	clienteEmail: string;
	clienteTelefone: string;
	clienteComplemento?: string;
	clienteNomeFantasia?: string;
	idTipoPessoa?: number;
	ativo?: boolean;
}

export enum EnumNivelAcessoUsuario {
	ADMIN = 1,
	FINANCEIRO = 2,
	FISCAL = 3,
	TECNICO = 4,
	VENDEDOR = 5,
	PRODUCAO = 6,
	SERVICO = 7,
}

export type TypePerfilAcessoUsuario =
	| "administrador"
	| "financeiro"
	| "tecnico"
	| "vendedor"
	| "producao"
	| "fiscal"
	| "servico";

export type TypeModuloPerfilAcessoUsuario =
	| "Cadastros"
	| "Romaneio"
	| "Ordem de Serviço"
	| "Relatório Proposta Comercial"
	| "Relatório Corte"
	| "Orçamento"
	| "Financeiro"
	| "Tarefas"
	| "Relatório Materiais"
	| "Relatório Montagem"
	| "Obras em Produção"
	| "Fiscal"
	| "Configurações"
	| "Relatório Custos"
	| "Relatório Medição";

export type TypeConfiguracaoValoresPerfilAcessoUsuario =
	| "visualizar valor custo"
	| "visualizar valor venda"
	| "manipular valor orçamento";

export interface NivelAcessoUsuarioFormData {
	perfilAcesso?: TypePerfilAcessoUsuario;
	configuracaoValores: TypeConfiguracaoValoresPerfilAcessoUsuario[];
}

export interface CadastroVendedorTecnicoFormData
	extends NivelAcessoUsuarioFormData {
	tecNome: string;
	tecDocumento: string;
	tecCep: string;
	tecEndereco: string;
	tecNumero: string;
	tecIdEstado?: number | null;
	tecIdCidade?: number | undefined;
	tecBairro: string;
	tecEmail: string;
	tecTelefone: string;
	tecComplemento?: string;
	tecNomeFantasia?: string;
	idTipoPessoa?: number;
	ativo: boolean;
	usuario: boolean;
}

export interface CadastrosUsuariosFormData extends NivelAcessoUsuarioFormData {
	nome: string;
	documento: string;
	email: string;
	ativo: boolean;
	idUsuario?: number;
}

export enum EnumTipoPessoa {
	TECNICO = 1,
	VENDEDOR = 2,
	CLIENTE = 3,
}

export interface CadastroPessoaDataPostResponse {
	idPessoa: number;
	idUsuario: number;
	usuario: boolean;
}
export interface CadastroPessoaDataPost {
	idTipoPessoa: number;
	idEstado?: number | null;
	idCidade?: number | undefined;
	nome: string;
	email?: string;
	telefone?: string;
	nomeFantasia?: string;
	documento?: string;
	cep?: string;
	endereco?: string;
	bairro?: string;
	numero?: string;
	complemento?: string;
	usuario?: boolean;
	acessos: number[];
}

export interface GetListaPessoas {
	idPessoa: number;
	idTipoPessoa: number;
	nome: string;
	documento: string;
	estado: {
		idEstado: number;
		nome: string;
		sigla: string;
	};
	cidade: {
		idEstado?: number | null;
		idCidade?: number | undefined;
		nome: string;
	};
	telefone: string;
	nomeFantasia: string;
	cep: number | string;
	ativo: boolean;
	endereco: string;
	bairro: string;
	numero: string;
	complemento: string;
	email: string;
	usuario: boolean;
}

export interface IParamsPessoas extends CommonQueryParams {
	idsTipoPessoa?: number[];
	mostrarAtivos?: boolean;
}

export interface GetCadastroPessoas {
	idPessoa: number;
	idTipoPessoa: number;
	nome: string;
	documento: string;
	endereco: string;
	cep: string;
	bairro: string;
	idEstado?: number | null;
	idCidade?: number | undefined;
	numero: string;
	complemento: string;
	email: string;
	telefone: string;
	nomeFantasia: string;
	ativo: boolean;
}

export interface GetIdPessoas {
	ativo: boolean;
	bairro: string;
	cep: string;
	complemento: string;
	documento: string;
	email: string;
	endereco: string;
	idEstado?: number | null;
	idCidade?: number | undefined;
	idPessoa: number;
	idTipoPessoa: number;
	nome: string;
	nomeFantasia: string;
	numero: string;
	telefone: string;
	usuario: boolean;
	funcoesInquilino: any[];
	funcoesUsuario: number[];
	podeReenviarToken: boolean;
}

export interface IPutCadastroUsuarioForm {
	codigoOtp: string;
	email: string;
	nomeDoUsuario: string;
	senha: string;
	confirmarSenha: string;
}

export interface IPutToken {
	email: string;
}

export interface IPostValidarToken {
	token: string;
}
