import { createContext, useEffect, useMemo, useState } from "react";
import { getVersion } from "services/modules/common";
import { FSelect } from "shared/interfaces/select";
import { useNavigation } from "./notificationsContext";
import { useAuthPermission } from "hooks/useAuthPermission";
import { EnumModulePermissions } from "shared/interfaces/authPermission";

export type GlobalContextProvider = {
	children: JSX.Element;
};

export type GlobalContextValues = {
	estados: FSelect[];
	handleEstados: (values: FSelect[]) => void;
	version: string;
	permissionDashboard: boolean;
	permissionCatalogoTecnico: boolean;
	permissionCadastrarGrupoCorServico: boolean;
	permissionProjetos: boolean;
	permissionOrcamento: boolean;
	permissionUsuarios: boolean;
	permissionTarefas: boolean;
	permissionFinanceiro: boolean;
	permissionFiscal: boolean;
	permissionOrdemServico: boolean;
	permissionProducao: boolean;
	permissionRomaneio: boolean;
	permissionVendedor: boolean;
	permissionTecnico: boolean;
	permissionVerEditarCusto: boolean;
	permissionVerValorVendas: boolean;
	permissionEtapaProducaoOrcamento: boolean;
	permissionOtimizacaoBarra: boolean;
	permissionEditarOtimizacaoBarra: boolean;
	permissionPainelOrcamento: boolean;
	permissionEditarPainelOrcamento: boolean;
	permissionVerEditarUsuario: boolean;
	permissionEditarLogoEmpresa: boolean;
	permissionEnviarDadosContasAReceber: boolean;
	permissionPrecificacaoMateriais: boolean;
	permissionPropostaComercial: boolean;
	permissionRelatorioMateriais: boolean;
	permissionRelatorioCustos: boolean;
	permissionRelatorioCorte: boolean;
	permissionRelatorioMontagem: boolean;
	permissionRelatorioMedicao: boolean;
	permissionEditProjeto: boolean;
	permissionViewProjeto: boolean;
};

export const GlobalContext = createContext({} as GlobalContextValues);

export const GlobalProvider = ({ children }: GlobalContextProvider) => {
	const { openNotification } = useNavigation();
	const [estados, setEstados] = useState<FSelect[]>([]);
	const [version, setVersion] = useState("");

	const permissionDashboard = useAuthPermission({
		rule: ["Admin", "Financeiro", "Fiscal"],
	});

	const permissionCatalogoTecnico = useAuthPermission({
		rule: ["Admin", "Tecnico"],
	});

	const permissionCadastrarGrupoCorServico = useAuthPermission({
		rule: ["Admin", "Tecnico"],
	});

	const permissionProjetos = useAuthPermission({
		rule: ["Admin", "Tecnico"],
	});

	const permissionOrcamento = useAuthPermission({
		module: EnumModulePermissions.ORCAMENTO,
		rule: ["Admin", "Financeiro", "Tecnico", "Vendedor", "Fiscal"],
	});

	const permissionUsuarios = useAuthPermission({
		rule: ["Admin"],
	});

	const permissionTarefas = useAuthPermission({
		module: EnumModulePermissions.SERVICOS,
		rule: ["Admin", "Financeiro", "Tecnico", "Vendedor", "Fiscal", "Servico"],
	});

	const permissionFinanceiro = useAuthPermission({
		module: EnumModulePermissions.FINANCEIRO,
		rule: ["Admin", "Financeiro"],
	});

	const permissionFiscal = useAuthPermission({
		module: EnumModulePermissions.FISCAL,
		rule: ["Admin", "Fiscal"],
	});

	const permissionOrdemServico = useAuthPermission({
		module: EnumModulePermissions.SERVICOS,
		rule: ["Admin", "Financeiro", "Tecnico", "Vendedor", "Fiscal", "Servico"],
	});

	const permissionProducao = useAuthPermission({
		module: EnumModulePermissions.PRODUCAO,
		rule: ["Admin", "Financeiro", "Tecnico", "Vendedor", "Producao", "Fiscal"],
	});

	const permissionRomaneio = useAuthPermission({
		rule: ["Admin", "Financeiro", "Tecnico", "Vendedor", "Fiscal"],
	});

	const permissionVendedor = useAuthPermission({
		rule: ["Vendedor"],
	});

	const permissionTecnico = useAuthPermission({
		rule: ["Tecnico"],
	});

	const permissionVerEditarCusto = useAuthPermission({
		rule: ["Admin", "Financeiro", "Fiscal", "Tecnico"],
	});

	const permissionVerValorVendas = useAuthPermission({
		rule: ["Admin", "Financeiro", "Fiscal", "Vendedor"],
	});

	const permissionEtapaProducaoOrcamento = useAuthPermission({
		rule: ["Admin", "Financeiro", "Tecnico", "Fiscal"],
	});

	const permissionOtimizacaoBarra = useAuthPermission({
		rule: [
			"Admin",
			"Financeiro",
			"Fiscal",
			"Tecnico",
			"Vendedor",
			"Servico",
			"Producao",
		],
	});

	const permissionEditarOtimizacaoBarra = useAuthPermission({
		rule: ["Admin", "Financeiro", "Fiscal", "Tecnico"],
	});

	const permissionPainelOrcamento = useAuthPermission({
		rule: [
			"Admin",
			"Financeiro",
			"Fiscal",
			"Tecnico",
			"Vendedor",
			"Servico",
			"Producao",
		],
	});

	const permissionEditarPainelOrcamento = useAuthPermission({
		rule: ["Admin", "Financeiro", "Fiscal"],
	});

	const permissionVerEditarUsuario = useAuthPermission({
		rule: ["Admin"],
	});

	const permissionEditarLogoEmpresa = useAuthPermission({
		rule: ["Admin"],
	});

	const permissionEnviarDadosContasAReceber = useAuthPermission({
		rule: ["Admin", "Financeiro", "Fiscal"],
	});

	// RELATORIOS

	const permissionPrecificacaoMateriais = useAuthPermission({
		rule: ["Admin", "Financeiro", "Fiscal", "Tecnico"],
	});

	const permissionPropostaComercial = useAuthPermission({
		rule: ["Admin", "Financeiro", "Fiscal", "Tecnico", "Vendedor"],
	});

	const permissionRelatorioMateriais = useAuthPermission({
		rule: ["Admin", "Financeiro", "Fiscal", "Tecnico"],
	});

	const permissionRelatorioCustos = useAuthPermission({
		rule: ["Admin", "Financeiro", "Fiscal", "Tecnico"],
	});

	const permissionRelatorioCorte = useAuthPermission({
		module: EnumModulePermissions.REL_CORTE_MONTAGEM,
		rule: ["Admin", "Financeiro", "Fiscal", "Tecnico", "Producao"],
	});

	const permissionRelatorioMontagem = useAuthPermission({
		module: EnumModulePermissions.REL_CORTE_MONTAGEM,
		rule: ["Admin", "Financeiro", "Fiscal", "Tecnico", "Producao"],
	});

	const permissionRelatorioMedicao = useAuthPermission({
		rule: ["Admin", "Financeiro", "Fiscal", "Tecnico", "Producao"],
	});

	const permissionEditProjeto = useAuthPermission({
		rule: ["Admin", "Tecnico"],
	});

	const permissionViewProjeto = useAuthPermission({
		rule: ["Admin", "Tecnico"],
	});

	const getVersionData = async () => {
		try {
			const { versao } = await getVersion();
			setVersion(versao);
		} catch (error: any) {
			openNotification({
				isOpen: true,
				message: error?.message,
				type: "error",
			});
		}
	};

	const handleEstados = (values: FSelect[]) => {
		setEstados(values);
	};

	useEffect(() => {
		getVersionData();
	}, []);

	const values: GlobalContextValues = useMemo(() => {
		return {
			estados,
			handleEstados,
			version,
			permissionDashboard,
			permissionCatalogoTecnico,
			permissionCadastrarGrupoCorServico,
			permissionProjetos,
			permissionOrcamento,
			permissionUsuarios,
			permissionTarefas,
			permissionFinanceiro,
			permissionFiscal,
			permissionOrdemServico,
			permissionProducao,
			permissionRomaneio,
			permissionVendedor,
			permissionTecnico,
			permissionVerEditarCusto,
			permissionVerValorVendas,
			permissionEtapaProducaoOrcamento,
			permissionOtimizacaoBarra,
			permissionPainelOrcamento,
			permissionEditarPainelOrcamento,
			permissionVerEditarUsuario,
			permissionEditarLogoEmpresa,
			permissionEditarOtimizacaoBarra,
			permissionEnviarDadosContasAReceber,
			permissionPrecificacaoMateriais,
			permissionPropostaComercial,
			permissionRelatorioMateriais,
			permissionRelatorioCustos,
			permissionRelatorioCorte,
			permissionRelatorioMontagem,
			permissionRelatorioMedicao,
			permissionEditProjeto,
			permissionViewProjeto,
		};
	}, [
		estados,
		version,
		permissionDashboard,
		permissionCatalogoTecnico,
		permissionCadastrarGrupoCorServico,
		permissionProjetos,
		permissionOrcamento,
		permissionUsuarios,
		permissionTarefas,
		permissionFinanceiro,
		permissionFiscal,
		permissionOrdemServico,
		permissionProducao,
		permissionRomaneio,
		permissionVendedor,
		permissionTecnico,
		permissionVerEditarCusto,
		permissionVerValorVendas,
		permissionEtapaProducaoOrcamento,
		permissionOtimizacaoBarra,
		permissionPainelOrcamento,
		permissionEditarPainelOrcamento,
		permissionVerEditarUsuario,
		permissionEditarLogoEmpresa,
		permissionEditarOtimizacaoBarra,
		permissionEnviarDadosContasAReceber,
		permissionPrecificacaoMateriais,
		permissionPropostaComercial,
		permissionRelatorioMateriais,
		permissionRelatorioCustos,
		permissionRelatorioCorte,
		permissionRelatorioMontagem,
		permissionRelatorioMedicao,
		permissionEditProjeto,
		permissionViewProjeto,
	]);

	return (
		<GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>
	);
};
