import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";

type MobilePaginationProps = {
	page: number;
	pageSize: number;
	totalItems: number;
	onChangePage: (page: number) => void;
};

export default function MobilePagination({
	pageSize,
	page,
	totalItems,
	onChangePage,
}: MobilePaginationProps) {
	const handlePressBack = () => {
		if (page <= 1) return;
		onChangePage(page - 1);
	};

	const handlePressNext = () => {
		if (page < Math.ceil(totalItems / pageSize)) onChangePage(page + 1);
	};

	const getLabelDisplayedRowsTo = () => {
		if (totalItems === -1) {
			return (page + 1) * pageSize;
		}
		return pageSize === -1 ? totalItems : Math.min(totalItems, page * pageSize);
	};

	const getPaginationText = () => {
		const from = totalItems === 0 ? 0 : (page - 1) * pageSize + 1;
		const to = getLabelDisplayedRowsTo();
		const total = totalItems === -1 ? -1 : totalItems;

		return `${from}-${to} de ${total}`;
	};

	return (
		<Stack direction="row" alignItems="center">
			<IconButton color="primary" onClick={handlePressBack}>
				<ChevronLeft />
			</IconButton>

			<Typography
				flex={1}
				textAlign="center"
				fontFamily="Roboto"
				fontWeight={400}
				fontSize={12}
				color="#00000080"
			>
				{getPaginationText()}
			</Typography>

			<IconButton color="primary" onClick={handlePressNext}>
				<ChevronRight />
			</IconButton>
		</Stack>
	);
}
