import { Box, Typography } from "@mui/material";
import { ContainerYesNoButtons } from "components/ContainerYesNoButtons";
import ModalComponent from "components/Modal";
import { fontsSizes } from "styles/theme";

interface ModalEmailProps {
	isOpen: boolean;
	onClose: () => void;
	naoMudar: () => void;
}

export function ModalEmail({ isOpen, onClose, naoMudar }: ModalEmailProps) {
	return (
		<ModalComponent
			open={isOpen}
			handleClose={naoMudar}
			width={{ md: "30%", sm: "50%", xs: "90%" }}
			height="auto"
			title="E-mail"
		>
			<Box px={3} py={2}>
				<Typography fontSize={fontsSizes.large} mb={1} color="GrayText">
					Deseja trocar o e-mail?
				</Typography>
				<Typography fontSize={fontsSizes.small}>
					*Ao trocar, o usuário ativo continuará acessando o software
					normalmente
				</Typography>
			</Box>
			<ContainerYesNoButtons onNo={naoMudar} onYes={onClose} />
		</ModalComponent>
	);
}
