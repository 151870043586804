import * as React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { FilledInputProps, InputAdornment } from "@mui/material";
import { CustomFilledInput, SearchBarColorVariants } from "./styles";

export type SearchInputProps = FilledInputProps & {
	bgColor?: SearchBarColorVariants;
	value?: string;
	onChange?: (
		value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => void;
	rightComponent?: React.ReactNode;
};

export const SearchInput: React.FC<SearchInputProps> = ({
	bgColor = "gray_dark",
	value,
	onChange,
	rightComponent,
	...props
}) => {
	return (
		<CustomFilledInput
			{...props}
			disableUnderline
			bgColor={bgColor}
			value={value}
			onChange={(event) => onChange?.(event)}
			endAdornment={
				<InputAdornment position="start">
					{rightComponent ? (
						rightComponent
					) : (
						<SearchIcon fontSize="small" sx={{ color: "#585858" }} />
					)}
				</InputAdornment>
			}
		/>
	);
};
