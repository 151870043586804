import * as Yup from "yup";
import { errorMessage } from "shared/messages/errorMessages";
import { validarDocumento } from "shared/validation/documento";

const { IS_REQUIRED } = errorMessage;

export const usuarioSchemaValidation = Yup.object().shape({
	nome: Yup.string().required(IS_REQUIRED).trim(),
	documento: Yup.string()
		.required(IS_REQUIRED)
		.test("documento", "CPF/CNPJ inválido", validarDocumento),
	email: Yup.string().email("Digite um e-mail válido!"),
});
