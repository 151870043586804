import { createContext, useState, ReactNode } from "react";
import { IAnexos } from "shared/interfaces/ordemServico";

interface OrdemServicoProviderProps {
	children: ReactNode;
}

export type DadosOSContextProps = {
	idSelecionado: number | undefined;
	setIdSelecionado: (idSelecionado: number | undefined) => void;
	dadosOriginaisAnexo: IAnexos[];
	setDadosOriginaisAnexo: (dadosOriginaisAnexo: IAnexos[]) => void;
	dadosOriginaisEndereco: boolean;
	setDadosOriginaisEndereco: (dadosOriginaisEndereco: boolean) => void;
	isOnClose: boolean;
	setIsOnClose: (isOnClose: boolean) => void;
	arquivoAssinatura: string;
	setArquivoAssinatura: (arquivoAssinatura: string) => void;
};

export const DadosOSContext = createContext({} as DadosOSContextProps);

export function DadosOSProvider({ children }: OrdemServicoProviderProps) {
	const [idSelecionado, setIdSelecionado] = useState<number | undefined>(
		undefined,
	);
	const [dadosOriginaisAnexo, setDadosOriginaisAnexo] = useState<IAnexos[]>([]);
	const [dadosOriginaisEndereco, setDadosOriginaisEndereco] =
		useState<boolean>(false);
	const [isOnClose, setIsOnClose] = useState(false);
	const [arquivoAssinatura, setArquivoAssinatura] = useState("");
	return (
		<DadosOSContext.Provider
			value={{
				idSelecionado,
				setIdSelecionado,
				isOnClose,
				setIsOnClose,
				dadosOriginaisAnexo,
				setDadosOriginaisAnexo,
				dadosOriginaisEndereco,
				setDadosOriginaisEndereco,
				arquivoAssinatura,
				setArquivoAssinatura,
			}}
		>
			{children}
		</DadosOSContext.Provider>
	);
}
