import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import * as S from "./styles";

const easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
	initial: {
		y: 60,
		opacity: 0,
		transition: { duration: 0.6, ease: easing },
	},
	animate: {
		y: 0,
		opacity: 1,
		transition: {
			duration: 0.6,
			ease: easing,
		},
	},
};

interface LoadingProps {
	isFixed?: boolean;
}

export default function Loading({ isFixed }: LoadingProps) {
	return (
		<Box
			sx={{
				position: isFixed ? "fixed" : "relative",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: "100vh",
				width: "100vw",
				flexDirection: "column",
				left: 0,
				top: 0,
			}}
		>
			<S.Loading />
			<Typography
				component={motion.p}
				{...fadeInUp}
				variant="body2"
				align="center"
				color={"#005c8f"}
				sx={{ mt: 3 }}
			>
				Carregando Dados...
			</Typography>
		</Box>
	);
}
