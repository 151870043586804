import { useAuth } from "contexts/authContext";
import {
	EnumModulePermissions,
	PermissionRules,
} from "shared/interfaces/authPermission";

interface UseAuthPermissionProps {
	rule: PermissionRules | PermissionRules[];
	module?: EnumModulePermissions | EnumModulePermissions[];
}

export function useAuthPermission({ rule, module }: UseAuthPermissionProps) {
	const { user, modulosInquilino, tipoUsuario } = useAuth();

	let permissionModule = false;
	let permissionRole = false;

	if (!user) {
		return false;
	}

	// Verificação do modulo
	if (Array.isArray(module) && module.length) {
		permissionModule = module.some((item) => modulosInquilino.includes(item));
	} else if (!Array.isArray(module) && module) {
		permissionModule = modulosInquilino.includes(module);
	} else {
		permissionModule = true;
	}

	// Verificação da função
	if (Array.isArray(rule) && rule.length) {
		permissionRole = rule.some((item) => tipoUsuario.includes(item));
	} else if (!Array.isArray(rule) && rule) {
		permissionRole = tipoUsuario.includes(rule);
	} else {
		permissionRole = true;
	}

	return permissionModule && permissionRole;
}
