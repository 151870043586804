import {
	styled,
	Drawer as MuiDrawer,
	Theme,
	CSSObject,
	Avatar,
	Stack,
} from "@mui/material";
import { commonScrollbar } from "pages/Projetos/components/ListWithScrollbar";
import { drawer_width_desktop } from ".";

export const DesktopMenuDrawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: theme.spacing(drawer_width_desktop),
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	position: "fixed",
	zIndex: theme.zIndex.drawer + 1,
	[theme.breakpoints.down("md")]: {
		display: "none",
	},
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

export const openedMixin = (theme: Theme): CSSObject => ({
	width: theme.spacing(drawer_width_desktop),
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

export const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(10.5)} + 1px)`,
});

export const AvatarDrawer = styled(Avatar)`
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
`;

export const ButtonDrawer = styled("button")`
	width: 24px;
	height: 36px;
	background-color: #b0d3e7;
	border: none;
	border-radius: 4px 0px 0px 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-size: 16px;
	color: #46545b;
`;

export const IconButtonDrawer = styled("div")`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	width: 45px;
	height: 45px;
	cursor: pointer;
	transition: all ease-in-out 200ms;

	&:hover {
		background-color: #e6e6e6;
	}
`;

export const CemWebMiniIcon = styled("img")`
	margin-top: 16px;
	width: 24px;
`;

export const StackScroll = styled(Stack)`
	overflow: auto;
	${commonScrollbar}
`;
