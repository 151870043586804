import { Box, Grid, Typography } from "@mui/material";
import { CustomCheckbox } from "components/CustomCheckbox";
import { useAuthPermission } from "hooks/useAuthPermission";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { EnumModulePermissions } from "shared/interfaces/authPermission";
import {
	EnumNivelAcessoUsuario,
	NivelAcessoUsuarioFormData,
	TypeConfiguracaoValoresPerfilAcessoUsuario,
	TypeModuloPerfilAcessoUsuario,
	TypePerfilAcessoUsuario,
} from "shared/interfaces/pessoas";
import { fontsSizes } from "styles/theme";

const SHOW_CONFIGURACAO_VALORES = false;

export type TypeNivelAcessoArray = {
	name: TypePerfilAcessoUsuario;
	id: EnumNivelAcessoUsuario;
};

export const nivelAcesso: TypeNivelAcessoArray[] = [
	{
		name: "administrador",
		id: EnumNivelAcessoUsuario.ADMIN,
	},
	{
		name: "financeiro",
		id: EnumNivelAcessoUsuario.FINANCEIRO,
	},
	{
		name: "tecnico",
		id: EnumNivelAcessoUsuario.TECNICO,
	},
	{
		name: "vendedor",
		id: EnumNivelAcessoUsuario.VENDEDOR,
	},
	{
		name: "producao",
		id: EnumNivelAcessoUsuario.PRODUCAO,
	},
	{
		name: "fiscal",
		id: EnumNivelAcessoUsuario.FISCAL,
	},
	{
		name: "servico",
		id: EnumNivelAcessoUsuario.SERVICO,
	},
];

type TypeCheckboxesAcessos = {
	name: TypePerfilAcessoUsuario;
	title: string;
	subtitle?: string;
	hasPermission: boolean;
	modulosPermitidos: TypeModuloPerfilAcessoUsuario[];
	configuracaoValores: TypeConfiguracaoValoresPerfilAcessoUsuario[];
	configuracaoValoresDisabled: TypeConfiguracaoValoresPerfilAcessoUsuario[];
};

type TypeModulosPermitidos = {
	name: TypeModuloPerfilAcessoUsuario;
	hasPermission: boolean;
};

type TypeConfiguracaoValores = {
	name: TypeConfiguracaoValoresPerfilAcessoUsuario;
	title: string;
	subtitle?: string;
};

const configuracaoValores: TypeConfiguracaoValores[] = [
	{
		name: "visualizar valor custo",
		title: "Visualizar valor de custo",
		subtitle: "(Em todo o sistema)",
	},
	{
		name: "visualizar valor venda",
		title: "Visualizar valor de venda",
		subtitle: "(Em todo o sistema)",
	},
	{
		name: "manipular valor orçamento",
		title: "Manipular valor de orçamento",
	},
];

// TODO - criar testes

interface NivelAcessoUsuarioProps {
	isLoading?: boolean;
}

export function NivelAcessoUsuario({ isLoading }: NivelAcessoUsuarioProps) {
	const { setValue, watch } = useFormContext<NivelAcessoUsuarioFormData>();

	const permissionModuloOrcamento = useAuthPermission({
		module: EnumModulePermissions.ORCAMENTO,
		rule: "Admin",
	});

	const permissionModuloFinanceiro = useAuthPermission({
		module: EnumModulePermissions.FINANCEIRO,
		rule: "Admin",
	});
	const permissionModuloFiscal = useAuthPermission({
		module: EnumModulePermissions.FISCAL,
		rule: "Admin",
	});
	const permissionModuloProducao = useAuthPermission({
		module: EnumModulePermissions.PRODUCAO,
		rule: "Admin",
	});
	const permissionModuloRelatorioCorteMontagem = useAuthPermission({
		module: EnumModulePermissions.REL_CORTE_MONTAGEM,
		rule: "Admin",
	});
	const permissionModuloServicos = useAuthPermission({
		module: EnumModulePermissions.SERVICOS,
		rule: "Admin",
	});

	const perfisAcesso: TypeCheckboxesAcessos[] =
		useMemo((): TypeCheckboxesAcessos[] => {
			return [
				{
					name: "administrador",
					title: "Administrador",
					hasPermission: true,
					subtitle: "(Acesso a todo sistema)",
					modulosPermitidos: [
						"Cadastros",
						"Orçamento",
						"Obras em Produção",
						"Romaneio",
						"Financeiro",
						"Fiscal",
						"Tarefas",
						"Ordem de Serviço",
						"Configurações",
						"Relatório Corte",
						"Relatório Custos",
						"Relatório Materiais",
						"Relatório Medição",
						"Relatório Montagem",
						"Relatório Proposta Comercial",
					],
					configuracaoValores: [
						"manipular valor orçamento",
						"visualizar valor custo",
						"visualizar valor venda",
					],
					configuracaoValoresDisabled: [
						"manipular valor orçamento",
						"visualizar valor custo",
						"visualizar valor venda",
					],
				},
				{
					name: "financeiro",
					title: "Financeiro",
					hasPermission: permissionModuloFinanceiro,
					subtitle:
						"(Acesso a todo o sistema com exceção do Mód. Cadastro e Fiscal)",
					modulosPermitidos: [
						"Orçamento",
						"Obras em Produção",
						"Romaneio",
						"Financeiro",
						"Tarefas",
						"Ordem de Serviço",
						"Configurações",
						"Relatório Corte",
						"Relatório Custos",
						"Relatório Materiais",
						"Relatório Medição",
						"Relatório Montagem",
						"Relatório Proposta Comercial",
					],
					configuracaoValores: ["manipular valor orçamento"],
					configuracaoValoresDisabled: ["manipular valor orçamento"],
				},
				{
					name: "tecnico",
					title: "Técnico",
					hasPermission: true,
					subtitle:
						"(Acesso a todos os módulos com exceção ao Financeiro e Fiscal)",
					modulosPermitidos: [
						"Cadastros",
						"Orçamento",
						"Obras em Produção",
						"Romaneio",
						"Tarefas",
						"Ordem de Serviço",
						"Configurações",
						"Relatório Corte",
						"Relatório Custos",
						"Relatório Materiais",
						"Relatório Medição",
						"Relatório Montagem",
						"Relatório Proposta Comercial",
					],
					configuracaoValores: ["visualizar valor custo"],
					configuracaoValoresDisabled: ["visualizar valor custo"],
				},
				{
					name: "vendedor",
					title: "Vendedor",
					hasPermission: permissionModuloOrcamento,
					subtitle:
						"(Acesso apenas ao Mód. Orçamento até a etapa de Proposta e Rel. Proposta Comercial",
					modulosPermitidos: [
						"Cadastros",
						"Orçamento",
						"Obras em Produção",
						"Romaneio",
						"Tarefas",
						"Ordem de Serviço",
						"Configurações",
						"Relatório Proposta Comercial",
					],
					configuracaoValores: ["visualizar valor venda"],
					configuracaoValoresDisabled: ["visualizar valor venda"],
				},
				{
					name: "producao",
					title: "Produção",
					hasPermission: permissionModuloProducao,
					subtitle:
						"(Acesso apenas ao Mód. Obras em Produção e Relatórios de Medição, Corte e Montagem)",
					modulosPermitidos: [
						"Obras em Produção",
						"Relatório Medição",
						"Relatório Corte",
						"Relatório Montagem",
						"Configurações",
					],
					configuracaoValores: [],
					configuracaoValoresDisabled: [
						"manipular valor orçamento",
						"visualizar valor custo",
						"visualizar valor venda",
					],
				},
				{
					name: "fiscal",
					title: "Fiscal",
					hasPermission: permissionModuloFiscal,
					subtitle:
						"(Acesso a todo o sistema com exceção do Mód. Cadastro e Financeiro)",
					modulosPermitidos: [
						"Orçamento",
						"Obras em Produção",
						"Romaneio",
						"Fiscal",
						"Tarefas",
						"Ordem de Serviço",
						"Configurações",
						"Relatório Corte",
						"Relatório Custos",
						"Relatório Materiais",
						"Relatório Medição",
						"Relatório Montagem",
						"Relatório Proposta Comercial",
					],
					configuracaoValores: ["manipular valor orçamento"],
					configuracaoValoresDisabled: ["manipular valor orçamento"],
				},
				{
					name: "servico",
					title: "Serviço",
					hasPermission: permissionModuloServicos,
					subtitle: "(Acesso apenas ao Mód. Ordem de Serviços e Tarefas)",
					modulosPermitidos: ["Ordem de Serviço", "Tarefas", "Configurações"],
					configuracaoValores: [],
					configuracaoValoresDisabled: [
						"manipular valor orçamento",
						"visualizar valor custo",
						"visualizar valor venda",
					],
				},
			];
		}, [
			permissionModuloOrcamento,
			permissionModuloFinanceiro,
			permissionModuloProducao,
			permissionModuloRelatorioCorteMontagem,
			permissionModuloServicos,
			permissionModuloFiscal,
		]);

	const modulosPermitidos: TypeModulosPermitidos[] =
		useMemo((): TypeModulosPermitidos[] => {
			return [
				{ name: "Cadastros", hasPermission: true },
				{ name: "Romaneio", hasPermission: permissionModuloOrcamento },
				{ name: "Ordem de Serviço", hasPermission: permissionModuloServicos },
				{
					name: "Relatório Proposta Comercial",
					hasPermission: permissionModuloOrcamento,
				},
				{
					name: "Relatório Corte",
					hasPermission: permissionModuloRelatorioCorteMontagem,
				},
				{ name: "Orçamento", hasPermission: permissionModuloOrcamento },
				{ name: "Financeiro", hasPermission: permissionModuloFinanceiro },
				{ name: "Tarefas", hasPermission: permissionModuloServicos },
				{
					name: "Relatório Materiais",
					hasPermission: permissionModuloOrcamento,
				},
				{
					name: "Relatório Montagem",
					hasPermission: permissionModuloRelatorioCorteMontagem,
				},
				{ name: "Obras em Produção", hasPermission: permissionModuloProducao },
				{ name: "Fiscal", hasPermission: permissionModuloFiscal },
				{ name: "Configurações", hasPermission: true },
				{ name: "Relatório Custos", hasPermission: permissionModuloOrcamento },
				{ name: "Relatório Medição", hasPermission: permissionModuloOrcamento },
			];
		}, [
			permissionModuloOrcamento,
			permissionModuloFinanceiro,
			permissionModuloProducao,
			permissionModuloRelatorioCorteMontagem,
			permissionModuloServicos,
			permissionModuloFiscal,
		]);

	const getModulosPermitidosByPerfil = (
		modulo: TypeModuloPerfilAcessoUsuario,
		perfil?: TypePerfilAcessoUsuario,
	) => {
		if (!perfil) return false;
		const perfilSelecionado = perfisAcesso.find((item) => item.name === perfil);

		if (perfilSelecionado) {
			return perfilSelecionado.modulosPermitidos.includes(modulo);
		}
		return false;
	};

	const getDisabledConfiguracaoValoresByPerfil = (
		configuracao: TypeConfiguracaoValoresPerfilAcessoUsuario,
		perfil?: TypePerfilAcessoUsuario,
	) => {
		if (!perfil) return false;
		const perfilSelecionado = perfisAcesso.find((item) => item.name === perfil);

		if (perfilSelecionado) {
			return perfilSelecionado.configuracaoValoresDisabled.includes(
				configuracao,
			);
		}

		return false;
	};

	useEffect(() => {
		if (watch().perfilAcesso) {
			const perfilSelecionado = perfisAcesso.find(
				(item) => item.name === watch().perfilAcesso,
			);

			if (perfilSelecionado) {
				setValue(
					"configuracaoValores",
					perfilSelecionado.configuracaoValores ?? [],
				);
			}
		}
	}, [watch().perfilAcesso]);

	return (
		<>
			<Typography
				mt={2}
				mb={1.5}
				color={"primary"}
				sx={{ fontSize: fontsSizes.xlarge }}
			>
				Perfil de Acesso
			</Typography>
			<Grid container spacing={1} rowSpacing={2} mb={2}>
				{perfisAcesso.map((item, index) => {
					return (
						<Grid item xs={12} sm={4} md={4} key={index}>
							<CustomCheckbox
								name={item.name}
								label={
									<Box>
										<Typography
											sx={{
												fontSize: fontsSizes.medium,
												fontWeight: 600,
											}}
											color="primary"
										>
											{item.title}
										</Typography>
										<Typography
											sx={{ fontSize: fontsSizes.small }}
											color="primary"
										>
											{item.subtitle}
										</Typography>
									</Box>
								}
								checked={watch().perfilAcesso === item.name}
								onCheck={(event, checked) => {
									setValue("perfilAcesso", item.name);
								}}
								disabled={isLoading}
								sx={{
									ml: 0,
									mr: 0,
								}}
							/>
						</Grid>
					);
				})}
			</Grid>

			<Typography
				mt={2}
				mb={1.5}
				color={"primary"}
				sx={{ fontSize: fontsSizes.xlarge }}
			>
				Módulos e Relatórios Permitidos
			</Typography>
			<Grid container spacing={1} rowSpacing={2} mb={2}>
				{modulosPermitidos.map((item, index) => {
					if (!item.hasPermission) return null;

					return (
						<Grid
							item
							xs={12}
							sm={4}
							md={4}
							key={index}
							sx={{
								cursor: "not-allowed",
							}}
						>
							<CustomCheckbox
								name={item.name}
								label={
									<Box>
										<Typography
											sx={{
												fontSize: fontsSizes.medium,
												fontWeight: 600,
											}}
											color="primary"
										>
											{item.name}
										</Typography>
									</Box>
								}
								checked={getModulosPermitidosByPerfil(
									item.name,
									watch().perfilAcesso,
								)}
								sx={{
									ml: 0,
									mr: 0,
									pointerEvents: "none",
								}}
							/>
						</Grid>
					);
				})}
			</Grid>

			{SHOW_CONFIGURACAO_VALORES && (
				<>
					<Typography
						mt={2}
						mb={1.5}
						color={"primary"}
						sx={{ fontSize: fontsSizes.xlarge }}
					>
						Configuração de Valores
					</Typography>
					<Grid container spacing={1} rowSpacing={2} mb={2}>
						{configuracaoValores.map((item, index) => {
							return (
								<Grid item xs={12} sm={4} md={4} key={index}>
									<CustomCheckbox
										name={item.name}
										label={
											<Box>
												<Typography
													sx={{
														fontSize: fontsSizes.medium,
														fontWeight: 600,
													}}
													color="primary"
												>
													{item.title}
												</Typography>
												<Typography
													sx={{ fontSize: fontsSizes.small }}
													color="primary"
												>
													{item.subtitle}
												</Typography>
											</Box>
										}
										checked={watch("configuracaoValores").includes(item.name)}
										onCheck={(event, checked) => {
											if (checked) {
												setValue("configuracaoValores", [
													...watch("configuracaoValores"),
													item.name,
												]);
											} else {
												setValue(
													"configuracaoValores",
													watch("configuracaoValores").filter(
														(value) => value !== item.name,
													),
												);
											}
										}}
										disabled={
											getDisabledConfiguracaoValoresByPerfil(
												item.name,
												watch().perfilAcesso,
											) || isLoading
										}
										sx={{
											ml: 0,
											mr: 0,
										}}
									/>
								</Grid>
							);
						})}
					</Grid>
				</>
			)}
		</>
	);
}
