import { ChevronLeft } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Box } from "@mui/material";

interface BackFromDesenhadorProps {
	toggleDisplayDesenhador: (shouldShow: boolean) => void;
}

export default function BackFromDesenhador({
	toggleDisplayDesenhador,
}: BackFromDesenhadorProps) {
	return (
		<>
			<Box position={"fixed"} zIndex={9999}>
				<Button
					variant="contained"
					size={"medium"}
					onClick={() => {
						toggleDisplayDesenhador(false);
					}}
				>
					<ChevronLeft />
					Voltar
				</Button>
			</Box>
		</>
	);
}
