import { lazy } from "react";

export const SignIn = lazy(() => import("pages/SignIn"));
export const ForgotPassword = lazy(() => import("pages/ForgotPassword"));
export const NewPassword = lazy(() => import("pages/NewPassword"));
export const CadastroUsuario = lazy(() => import("pages/CadastroUsuario"));
export const Dashboard = lazy(() => import("pages/Dashboard"));
export const NotaFiscal = lazy(() => import("pages/NotaFiscal"));
export const NotaFiscalListagem = lazy(
	() => import("pages/NotaFiscalListagem"),
);
export const Fiscal = lazy(() => import("pages/Fiscal"));
export const PerfilLista = lazy(() => import("pages/Perfil/Lista"));
export const PerfilFormulario = lazy(() => import("pages/Perfil/Formulario"));
export const ServicosLista = lazy(() => import("pages/Servicos/Lista"));
export const ServicosFormulario = lazy(
	() => import("pages/Servicos/Formulario"),
);
export const VidrosLista = lazy(() => import("pages/Vidros/Lista"));
export const VidrosFormulario = lazy(() => import("pages/Vidros/Formulario"));

export const GruposLista = lazy(() => import("pages/Grupos/Lista"));
export const GrupoFormulario = lazy(
	() => import("pages/Grupos/Formulario/Grupo"),
);
export const CoresFomulario = lazy(
	() => import("pages/Grupos/Formulario/Cores"),
);
export const SuperficiesLista = lazy(() => import("pages/Superficies/Lista"));
export const SuperficiesFormulario = lazy(
	() => import("pages/Superficies/Formulario"),
);
export const ComponentesLista = lazy(() => import("pages/Componentes/Lista"));
export const ComponentesFormulario = lazy(
	() => import("pages/Componentes/Formulario"),
);

export const ProjetosLista = lazy(() => import("pages/Projetos/Lista"));
export const ProjetoFormulario = lazy(
	() => import("pages/Projetos/ContainerProjeto"),
);

export const KitsFormulario = lazy(() => import("pages/Kits/Formulario"));
export const KitsLista = lazy(() => import("pages/Kits/Lista"));

export const ListaOrcamento = lazy(() => import("pages/Orcamento/Lista/index"));
export const OrcamentoFormulario = lazy(
	() => import("pages/Orcamento/Formulario/index"),
);
export const GerenciarSistema = lazy(() => import("pages/GerenciarSistema"));

export const Clientes = lazy(() => import("pages/Clientes"));
export const CadastrarClientes = lazy(
	() => import("pages/Clientes/CadastrarClientes"),
);
export const Funcionarios = lazy(() => import("pages/Funcionarios"));
export const CadastrarFuncionarios = lazy(
	() => import("pages/Funcionarios/CadastroFuncionarios"),
);
export const Tarefas = lazy(() => import("pages/Tarefas"));

export const OrdemServico = lazy(() => import("pages/OrdemServico"));

export const NovoRomaneio = lazy(() => import("pages/Romaneio/Formulario"));
export const Romaneios = lazy(() => import("pages/Romaneio/listagemRomaneio"));

export const ObrasProducao = lazy(() => import("pages/ObrasProducao"));

export const ContasAPagar = lazy(() => import("pages/Financeiro/ContasAPagar"));
export const Fornecedor = lazy(() => import("pages/Financeiro/Fornecedor"));
export const ContasBancarias = lazy(
	() => import("pages/Financeiro/ContasBancarias"),
);
export const ContasAReceber = lazy(
	() => import("pages/Financeiro/ContasAReceber"),
);
export const Dre = lazy(() => import("pages/Financeiro/Dre"));
export const Cedentes = lazy(() => import("pages/Financeiro/Cedentes"));
export const DashboardFinanceiro = lazy(
	() => import("pages/Financeiro/DashboardFinanceiro"),
);
