import { yupResolver } from "@hookform/resolvers/yup";
import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material";
import { CustomButton } from "components/CustomButtom";
import { PreventExit } from "components/PreventExit";
import { ControlledTextField } from "components/TextField/ControlledTextField";
import { useNavigation } from "contexts/notificationsContext";
import { usePreventExit } from "hooks/usePreventExit";
import { ModalEmail } from "pages/Funcionarios/CadastroFuncionarios/ModalEmail";
import { ModalEmailEnviado } from "pages/Funcionarios/ModalDesativarUsuario";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { postPessoa, putPessoa } from "services/modules/person";
import { getPessoasById, putDesativarPessoa } from "services/modules/pessoas";
import { postToken } from "services/modules/pessoas/cadastroUsuario";
import {
	CadastroPessoaDataPost,
	CadastrosUsuariosFormData,
	EnumTipoPessoa,
	IPutToken,
} from "shared/interfaces/pessoas";
import { convertCpfCnpf, removeMaskCpfCnpj } from "shared/mask";
import { usuarioSchemaValidation } from "shared/schemas/usuario";
import { nivelAcesso, NivelAcessoUsuario } from "../NivelAcessoUsuario";
import { StackScroll } from "components/Sidebar/styles";

const defaultValues: CadastrosUsuariosFormData = {
	nome: "",
	documento: "",
	email: "",
	idUsuario: 0,
	ativo: true,
	perfilAcesso: undefined,
	configuracaoValores: [],
};

const getClienteInput = (index: number) => `client-textfield-${index}`;

export const CadastrosDeUsuarios: React.FC = () => {
	const params = useParams();
	const navigate = useNavigate();
	const { openNotification } = useNavigation();
	const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
	const [isLoadingData, setIsLoadingData] = useState(false);
	const [isLoadingReenviarToken, setIsLoadingReenviarToken] = useState(false);
	const [isOpenModalEmail, setIsOpenModalEmail] = useState(false);
	const [mudarEmail, setMudarEmail] = useState("");
	const [isOpenModalEmailEnviado, setIsOpenModalEmailEnviado] = useState(false);

	const {
		control,
		setValue,
		handleSubmit,
		reset,
		watch,
		setError,
		getValues,
		clearErrors,
		formState,
		...methods
	} = useForm<CadastrosUsuariosFormData>({
		defaultValues,
		resolver: yupResolver(usuarioSchemaValidation),
		mode: "onBlur",
	});

	const { isDirty, dirtyFields } = useFormState({ control });

	const {
		isOpenModalUnsaved,
		handleIsOpenModalUnsaved,
		handleLinkNavigateBlocked,
		handleOnCloseModalUnsavedEdit,
		linkNavigateBlocked,
		isGoBack,
		goBack,
	} = usePreventExit(isDirty && Object.values(dirtyFields).length > 0);

	const handleSubmitUsuario = async (data: CadastrosUsuariosFormData) => {
		setIsLoadingSubmit(true);
		try {
			const findPerfil = nivelAcesso.find(
				(item) => item.name === data.perfilAcesso,
			);

			const postData: CadastroPessoaDataPost = {
				nome: data.nome,
				documento: removeMaskCpfCnpj(data.documento),
				email: data.email,
				usuario: true,
				idTipoPessoa: EnumTipoPessoa.TECNICO,
				acessos: findPerfil ? [findPerfil.id] : [],
			};
			if (params.id) {
				await putPessoa(Number(params.id), postData);
				openNotification({
					isOpen: true,
					message: "Usuário atualizado com sucesso!",
					type: "success",
				});
				return;
			}
			const result = await postPessoa(postData);
			if (result) {
				setIsOpenModalEmailEnviado(true);
				reset(defaultValues);
				openNotification({
					isOpen: true,
					message: "Usuário cadastrado com sucesso!",
					type: "success",
				});
			}
		} catch (error: any) {
			openNotification({
				isOpen: true,
				message: error?.message,
				type: "error",
			});
		} finally {
			setIsLoadingSubmit(false);
		}
	};

	async function getUsuario(idPessoa: number) {
		setIsLoadingData(true);
		try {
			const result = await getPessoasById(idPessoa);
			if (result?.data) {
				setMudarEmail(result.data.email);

				const findPerfil = nivelAcesso.find(
					(perfil) => perfil.id === result.data.funcoesUsuario?.[0],
				);

				reset({
					nome: result.data.nome,
					documento: convertCpfCnpf(result.data.documento),
					email: result.data.email,
					idUsuario: result.data.idPessoa ?? 1,
					ativo: result.data.ativo,
					configuracaoValores: [],
					perfilAcesso: findPerfil?.name,
				});
			}
		} catch (error: any) {
			openNotification({
				isOpen: true,
				message: error?.message,
				type: "error",
			});
		} finally {
			setIsLoadingData(false);
		}
	}

	const handleSubmitReenviarToken = async (tecEmail: string) => {
		if (!tecEmail) return;
		setIsLoadingReenviarToken(true);
		try {
			const postReenviarToken: IPutToken = {
				email: tecEmail,
			};

			const result = await postToken(postReenviarToken);

			if (result) {
				openNotification({
					isOpen: true,
					message: "Token reenviado com sucesso!",
					type: "success",
				});
			}
		} catch (error: any) {
			openNotification({
				isOpen: true,
				message: error?.message,
				type: "error",
			});
		} finally {
			setIsLoadingReenviarToken(false);
		}
	};

	async function onDesativarUsuario(idPessoa: number) {
		try {
			const result = await putDesativarPessoa([idPessoa]);
			if (result) {
				openNotification({
					isOpen: true,
					message: watch().ativo
						? "Usuário Desativado com sucesso!"
						: "Usuário ativado com sucesso!",
					type: "success",
				});
				navigate("/usuarios");
			}
		} catch (error: any) {
			openNotification({
				isOpen: true,
				message: error?.message,
				type: "error",
			});
		}
	}

	useEffect(() => {
		params.id && getUsuario(Number(params.id));
	}, [params.id]);

	return (
		<FormProvider
			{...{
				control,
				handleSubmit,
				reset,
				setValue,
				watch,
				setError,
				getValues,
				clearErrors,
				formState,
				...methods,
			}}
		>
			<PreventExit
				handleIsOpenModalUnsaved={handleIsOpenModalUnsaved}
				handleLinkNavigateBlocked={handleLinkNavigateBlocked}
				isOpenModalUnsaved={isOpenModalUnsaved}
				handleOnCloseModalUnsavedEdit={handleOnCloseModalUnsavedEdit}
				linkNavigateBlocked={linkNavigateBlocked}
				isGoBack={isGoBack}
			>
				<StackScroll height={"calc(100vh - 260px)"} px={2}>
					<Grid container spacing={1}>
						<Grid item xs={12} sm={4} md={4}>
							<ControlledTextField
								id={getClienteInput(1)}
								control={control}
								name="nome"
								label={"Nome"}
								maxLength={80}
								disabled={isLoadingData}
								loading={isLoadingData}
							/>
						</Grid>
						<Grid item xs={12} sm={4} md={4}>
							<ControlledTextField
								control={control}
								name="documento"
								label={"CPF"}
								mask="cpfCnpj"
								maxLength={14}
								disabled={isLoadingData}
								loading={isLoadingData}
							/>
						</Grid>
						<Grid item xs={12} sm={4} md={4}>
							<ControlledTextField
								control={control}
								name="email"
								label="E-mail"
								maxLength={50}
								disabled={isLoadingData}
								loading={isLoadingData}
								onBlur={() => {
									if (!watch().email && params.id) {
										setIsOpenModalEmail(true);
									}
								}}
							/>
						</Grid>

						<NivelAcessoUsuario isLoading={isLoadingData} />
					</Grid>
				</StackScroll>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "flex-end",
						marginTop: 2,
					}}
				>
					<CustomButton
						variant="outlined"
						sx={{
							px: 2,
						}}
						onClick={() => {
							goBack(
								isDirty && Object.values(dirtyFields).length > 0,
								"/gerenciar/usuarios",
							);
						}}
					>
						Cancelar e sair
					</CustomButton>

					{Boolean(watch().idUsuario) && (
						<>
							<LoadingButton
								variant="outlined"
								onClick={() =>
									handleSubmitReenviarToken(getValues("email") || "")
								}
								loading={isLoadingReenviarToken}
								sx={{
									ml: 1,
								}}
							>
								REENVIAR TOKEN
							</LoadingButton>
							<CustomButton
								variant="outlined"
								onClick={() => onDesativarUsuario(Number(params.id))}
								color={watch().ativo ? "error" : "success"}
								sx={{
									ml: 1,
								}}
							>
								{watch().ativo ? "DESATIVAR" : "ATIVAR"}
							</CustomButton>
						</>
					)}

					<LoadingButton
						type="submit"
						variant="contained"
						endIcon={<Save />}
						onClick={handleSubmit(handleSubmitUsuario)}
						loading={isLoadingSubmit}
						sx={{
							marginLeft: 1,
						}}
					>
						Salvar
					</LoadingButton>
				</Box>

				<ModalEmailEnviado
					isOpen={isOpenModalEmailEnviado}
					onClose={() => setIsOpenModalEmailEnviado(false)}
				/>

				<ModalEmail
					isOpen={isOpenModalEmail}
					onClose={() => setIsOpenModalEmail(false)}
					naoMudar={() => {
						setValue("email", mudarEmail);
						setIsOpenModalEmail(false);
					}}
				/>
			</PreventExit>
		</FormProvider>
	);
};
