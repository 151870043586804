import { Checkbox, FormControlLabel, SxProps, Theme } from "@mui/material";
import { fontsSizes } from "styles/theme";

interface CustomCheckboxProps {
	name: string;
	label?: React.ReactNode;
	onCheck?: (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean,
	) => void;
	checked?: boolean;
	sx?: SxProps<Theme>;
	disabled?: boolean;
}

export function CustomCheckbox({
	name,
	label,
	onCheck,
	checked,
	sx,
	disabled,
}: CustomCheckboxProps) {
	return (
		<FormControlLabel
			sx={{
				ml: 1,
				mr: label ? 2 : 1,
				"& .MuiFormControlLabel-label": {
					fontSize: fontsSizes.medium,
				},
				"&.Mui-disabled": {
					cursor: "not-allowed",
				},
				"&.Mui-disabled p": {
					color: "GrayText",
					opacity: 0.8,
				},
				...sx,
			}}
			control={<Checkbox onChange={onCheck} checked={checked} />}
			name={name}
			label={label}
			disabled={disabled}
		/>
	);
}
