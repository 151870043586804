export type GridProjetoObserverActions =
	| "SAVE_ALL_PROFILES"
	| "SAVE_ALL_COMPONENTS"
	| "SAVE_ALL_GLASS"
	| "SAVE_ALL_SURFACES"
	| "SAVE_ALL_KITS"
	| "DELETE_UNSAVED_PROFILES"
	| "DELETE_UNSAVED_COMPONENTES"
	| "DELETE_UNSAVED_GLASS"
	| "DELETE_UNSAVED_SURFACES"
	| "DELETE_UNSAVED_KITS"
	| "DELETE_UNSAVED_USINAGEM";

type GridProjetoObserver = (
	tipo: GridProjetoObserverActions,
	values: any,
) => void;

class GridProjetoSubject {
	private observers: GridProjetoObserver[] = [];

	public subscribe(observer: GridProjetoObserver) {
		const isExist = this.observers.includes(observer);

		if (!isExist) {
			this.observers.push(observer);
		}
	}

	public unsubscribe(observer: GridProjetoObserver) {
		const observerIndex = this.observers.indexOf(observer);
		if (observerIndex === -1) return;
		this.observers.splice(observerIndex, 1);
	}

	public notify<T = any>(tipo: GridProjetoObserverActions, values: T) {
		this.observers.forEach((observer) => observer(tipo, values));
	}
}

const gridProjetoSubject = new GridProjetoSubject();

export default gridProjetoSubject;
