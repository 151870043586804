import { AxiosResponse } from "axios";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import { ApiResponse } from "shared/interfaces/api";
import {
	IPutVendedorParams,
	IUpdateVendedorApiResponse,
	GetVendedorApiResponse,
	CadastroPessoaDataPost,
	GetIdPessoas,
	CadastroPessoaDataPostResponse,
} from "shared/interfaces/pessoas";
import { api } from "../../axios/api";

export async function getClientId(
	idPessoa: number,
): Promise<GetIdPessoas | null> {
	if (!idPessoa) {
		throw new Error("Id pessoa não informado");
	}
	try {
		const response = await api
			.get<ApiResponse<GetIdPessoas>>(`/cadastros/pessoas/${idPessoa}`)
			.then((res) => res.data);
		return response.data;
	} catch (error: any) {
		getErrorsByApi(error, "Tivemos problemas internos ao buscar esse Cliente");
	}
	return null;
}

export async function updateVendedor(
	idOrcamento: number | string,
	data: IPutVendedorParams,
): Promise<IUpdateVendedorApiResponse | undefined> {
	try {
		return await api
			.put<IPutVendedorParams, AxiosResponse<IUpdateVendedorApiResponse>>(
				`/orcamentos/${idOrcamento}/atualizar-vendedor`,
				data,
			)
			.then((response) => response.data);
	} catch (error: any) {
		getErrorsByApi(error, "Tivemos problemas internos ao editar esse registro");
	}
}

export const getVendedorId = async (
	idVendedor: number | null,
): Promise<GetVendedorApiResponse | null> => {
	if (!idVendedor) {
		throw new Error("Id pessoa não informado");
	}
	try {
		const response: ApiResponse<GetVendedorApiResponse> = await api
			.get(`/cadastros/pessoas/${idVendedor}`)
			.then((res) => res.data);

		return response.data;
	} catch (error: any) {
		getErrorsByApi(
			error,
			"Tivemos problemas internos ao buscar esse Funcionario",
		);
	}
	return null;
};

export async function postPessoa(data: CadastroPessoaDataPost) {
	try {
		const response = await api
			.post<ApiResponse<CadastroPessoaDataPostResponse>>(
				"/autenticacao/usuario/adicionar",
				data,
			)
			.then((res) => res.data);

		return response.data;
	} catch (error: any) {
		getErrorsByApi(error, "Tivemos problemas internos ao criar esse registro");
	}
	return null;
}

export async function putPessoa(
	idPessoa: number,
	data: CadastroPessoaDataPost,
) {
	if (!idPessoa) {
		throw new Error("Id pessoa não informado");
	}
	try {
		const response = await api.put<ApiResponse<boolean>>(
			`/cadastros/pessoas/atualizar/${idPessoa}`,
			{ ...data, idPessoa },
		);

		return response.data.data;
	} catch (error: any) {
		getErrorsByApi(error, "Tivemos problemas internos ao editar esse registro");
	}
	return false;
}
