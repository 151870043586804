export const errorMessage = {
	IS_REQUIRED: "Campo é obrigatório.",
	MORE_THAN_ZERO: "Deve ser maior que zero",
	ONLY_NUMBERS: "Deve ser somente número",
	MAXIMUM_CHARACTER_IS_LENGTH: "Quantidade de caracteres para o campo excedida",
	IS_EMAIL_INVALID: "E-mail é inválido",
	CPFCNPJ: {
		MIN_11: "Digite um CPF ou CNPJ válido!",
	},
	SIGN_IN: {
		USERNAME: {
			IS_REQUIRED: "Campo é obrigatório.",
			IS_INVALID: "E-mail é inválido.",
		},
		PASSWORD: {
			IS_REQUIRED: "Campo é obrigatório.",
			IS_LENGTH: "Mínimo 6 caracteres.",
		},
	},
	SET_NEW_PASSWORD: {
		PASSWORD: {
			IS_REQUIRED: "Campo é obrigatória.",
			IS_LENGTH: "Mínimo 6 caracteres.",
		},
		CONFIRM_PASSWORD: {
			MUST_MATCH: "Senhas devem ser iguais",
		},
	},
	SEND_EMAIL: {
		EMAIL: {
			IS_REQUIRED: "Campo é obrigatório.",
			IS_INVALID: "E-mail é inválido.",
		},
	},
	PROFILE_RECORD: {
		REF_PROVIDER: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
		LINE: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
		WEIGHT: {
			IS_REQUIRED: "Campo é obrigatório.",
			IS_LENGTH: "Mínimo 1 kg.",
			MORE_THAN_ZERO: "Deve ser maior que 0.000",
		},
		CODE: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
		DESCRIPTION: {
			IS_REQUIRED: "Campo é obrigatório.",
			MAX_100: "Deve ter no máximo 100 caracteres",
		},
		BAR_HEIGHT: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
		PROFILE_TYPE: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
		SPECIAL_SIZES: {
			REQUIRED:
				"Para barra especial é necessário cadastrar pelo menos um dos campos tamanho da barra",
			POSITIVE: "Dever ser um número positivo",
			MIN: "Deve ser no mínimo 1",
		},
		SPECIAL_SIZE_ONE: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
		SPECIAL_SIZE_TWO: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
		SPECIAL_SIZE_THREE: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
	},
	PROFILE_PRICE: {
		PRICE_BY_METER: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
		PRICE_BY_BAR: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
		PRICE_BY_GROUP: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
		TYPE: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
	},
	PROFILE_SIMILARITY: {
		CODE: {
			IS_REQUIRED: "Campo é obrigatório.",
			IS_LENGTH: "Mínimo 5 caracteres.",
		},
		DESCRIPTION: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
		REF_PROVIDER: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
	},
	PROFILE_COMPOSITE: {
		PROFILE: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
		DESCRIPTION: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
		CHARACTERISTIC: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
		WEIGHT: {
			IS_REQUIRED: "Campo é obrigatório.",
		},
	},
	PROJECT: {
		MAX_80_CHARS: "Deve ser no máximo 80 caracteres",
	},
	TIPO_EXISTE: "Tipo informado já existe.",
	WEEKEND:
		"Final de semana e/ou feriado, será necessário cadastrar o pagamento no próximo dia útil.",
};
