import { Fragment } from "react";
import { AuthProvider } from "contexts/authContext";
import RoutesComponent from "routes";
import AlertComponent from "components/Alert";
import { NotificationsProvider } from "contexts/notificationsContext";
import { GlobalProvider } from "contexts/globalContext";
import { PostHogProvider } from "posthog-js/react";

const options = {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

function App() {
	return (
		<PostHogProvider
			apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
			options={options}
		>
			<NotificationsProvider>
				<AuthProvider>
					<GlobalProvider>
						<Fragment>
							<AlertComponent />
							<RoutesComponent />
						</Fragment>
					</GlobalProvider>
				</AuthProvider>
			</NotificationsProvider>
		</PostHogProvider>
	);
}

export default App;
